import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { GatewayModel } from '../../model/domain';
import { UpdateGatewayCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import { GatewayType, Language } from '../../model/enums';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';

const { Option } = Select;

type Props = {
  gateway: GatewayModel;
  onUpdate: (cmd: UpdateGatewayCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const GatewayDataForm: React.FC<Props> = ({
  gateway,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...gateway,
      name: gateway.name
        ? gateway.name.inSync
          ? gateway.name.actual
          : gateway.name.requested
        : '',
      language: gateway.language
        ? gateway.language.inSync
          ? gateway.language.actual
          : gateway.language.requested
        : Language.ENGLISH,
      tags: gateway.tags || [],
    });
  }, [gateway]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Gateway updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Gateway',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { name, type, language, tags } = values;
    const cmd: UpdateGatewayCmd = {};

    if (name !== gateway.name?.actual) {
      cmd.name = name;
    }

    if (type !== gateway.type) {
      cmd.type = type;
    }

    if (language !== gateway.language) {
      cmd.language = language;
    }

    if (tags !== gateway.tags) {
      cmd.tags = tags;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && gateway) {
      cmd.name =
        gateway.name && (gateway.name.requested || gateway.name.actual);
      cmd.type = gateway.type;
      cmd.language =
        gateway.language &&
        (gateway.language.requested || gateway.language.actual);
    }

    onUpdate(cmd);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Name" name="name" rules={[{ required: false }]}>
        <Input
          placeholder="Name of the Gateway"
          suffix={
            gateway.name &&
            (gateway.name.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            ))
          }
        />
      </Form.Item>

      <Form.Item label="Type" name="type" rules={[{ required: true }]}>
        <Select placeholder="Select Gateway Type" allowClear>
          <Option value={GatewayType.NOT_SET}>{'NOT_SET'}</Option>
          <Option value={GatewayType.CONTROLLER_V0}>{'CONTROLLER_V0'}</Option>
          <Option value={GatewayType.CONTROLLER_V1}>{'CONTROLLER_V1'}</Option>
          <Option value={GatewayType.CONTROLLER_V2}>{'CONTROLLER_V2'}</Option>
          <Option value={GatewayType.CONTROLLER_V3}>{'CONTROLLER_V3'}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={
          <Space direction="horizontal">
            <Typography>Language </Typography>
            {gateway.name &&
              (gateway.name.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              ))}
          </Space>
        }
        name="language"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select Language" allowClear>
          <Option value={Language.ENGLISH}>{'English'}</Option>
          <Option value={Language.THAI}>{'Thai'}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="tags">
        <Select
          mode="tags"
          placeholder="Tags used for filtering"
          allowClear={true}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Gateway
          </Button>
          <Button
            danger
            loading={updating}
            onClick={(e) => {
              if (gateway.isActive) {
                onUpdate({ isActive: false });
              } else {
                onUpdate({ isActive: true });
              }
            }}
          >
            {gateway.isActive ? 'Archive this Gateway' : 'Restore this Gateway'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
