import React from 'react';
import {
  Table,
  Tag,
  Col,
  Result,
  Typography,
  Row,
  Pagination,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getCycles } from '../../duck/modules/cycles';
import { functionalColor } from '../../colors';
import Column from 'antd/lib/table/Column';
import { CycleState } from '../../model/enums';
import { getDayOfCultivation } from '../../util';
import moment from 'moment';
import { SimpleCycleModel } from '../../model/simple';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    queryParams?: {
      page?: number;
      pageSize?: number;
    };
  };

const UnconnectedCyclesTable: React.FC<Props> = ({
  cycles,
  loading,
  error,
  total,
  reload,
  onReloaded,
  getCyclesConnect,
  queryParams,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    getCyclesConnect({ limit, offset });
    onReloaded();
  }, [offset, limit, reload]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load cycles"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return (
    <Col>
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={cycles}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Status"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.state === CycleState.ACTIVE) {
              return <Tag color={functionalColor.success}>Active</Tag>;
            } else if (cycle.state === CycleState.DONE) {
              return <Tag color={functionalColor.link}>Done</Tag>;
            }
            return <Tag>Not set</Tag>;
          }}
        />

        <Column
          align="center"
          title="DoC"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.startTimestamp) {
              return (
                <Typography>
                  {getDayOfCultivation(cycle.startTimestamp)}
                </Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Latest size - (pcs/kg)"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.latestSampleAggregation) {
              const latestAggregation = cycle.latestSampleAggregation;
              const today = new Date();
              const sampleDate = new Date(latestAggregation.date);
              today.setHours(0, 0, 0, 0);
              sampleDate.setHours(0, 0, 0, 0);

              var days = Math.round(
                moment
                  .duration(today.getTime() - sampleDate.getTime())
                  .asDays(),
              );

              var title = `${days} days ago: ${moment(sampleDate).format(
                'DD/MM',
              )}`;
              if (days == 0) {
                title = 'Today';
              } else if (days == 1) {
                title = 'Yesterday';
              }

              return (
                <Tooltip title={title}>
                  <Typography
                    style={{
                      color:
                        days < 2
                          ? functionalColor.primaryText
                          : functionalColor.secondaryText,
                    }}
                  >
                    {latestAggregation.mean.numberOfShrimpsPerKg}
                  </Typography>
                </Tooltip>
              );
            }

            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Cycle"
          render={(cycle: SimpleCycleModel) => (
            <Link key={cycle.id} to={`/cycles/${cycle.id}`}>
              {cycle.id}
            </Link>
          )}
        />

        <Column
          align="center"
          title="Organisation"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.organisation) {
              return (
                <Link
                  key={cycle.id}
                  to={`/organisations/${cycle.organisation.id}`}
                >
                  {cycle.organisation.name || cycle.organisation.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Farm"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.farm) {
              return (
                <Link key={cycle.id} to={`/farms/${cycle.farm.id}`}>
                  {cycle.farm.name || cycle.farm.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Pond"
          render={(cycle: SimpleCycleModel) => {
            if (cycle.pond) {
              return (
                <Link key={cycle.id} to={`/ponds/${cycle.pond.id}`}>
                  {cycle.pond.name || cycle.pond.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      </Table>
      {total != null && (
        <Row justify="end" style={{ marginTop: 10 }}>
          <Pagination
            total={total}
            defaultCurrent={page || 1}
            pageSize={currentPageSize}
            pageSizeOptions={['5', '10', '25', '50', '100', '200']}
            responsive={true}
            showSizeChanger={true}
            showTotal={(total) => `Total: ${total} items`}
            onChange={(page, pageSize) => {
              if (page && pageSize) {
                handleChange(pageSize, page);
              }
            }}
            onShowSizeChange={(_, pageSize) => {
              handleChange(pageSize, page);
            }}
          />
        </Row>
      )}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  cycles: state.cycles.list.cycles,
  loading: state.cycles.list.loading,
  error: state.cycles.list.error,
  total: state.cycles.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCyclesConnect: getCycles,
    },
    dispatch,
  );
};

export const CyclesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCyclesTable);
