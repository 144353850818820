import React, { useState } from 'react';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import {
  Row,
  Form,
  Select,
  Col,
  notification,
  Card,
  Typography,
  Button,
  InputNumber,
  Tag,
  List,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import {
  calibrateSensor,
  checkForCalibration,
} from '../../duck/modules/calibration';
import { CalibrationStep, } from '../../model/enums';
import store from '../../store';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from '../_util/hook';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    sensorId: string;
  };

const UnconnectedSensorCalibrationCard: React.FC<Props> = ({
  sensorId,
  triggerModel,
  calibrateableModel,
  checkForCalibrationConnected,
  calibrateSensorConnected,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ triggerModel });

  React.useEffect(() => {
    if (prev?.triggerModel.log === null && triggerModel.log) {
      notification.success({
        message: 'Calibration command send',
      });
    }
  }, [triggerModel]);

  React.useEffect(() => {
    if (prev?.triggerModel.error === null && triggerModel.error) {
      notification.error({
        message: 'Error while processing calibration command',
        description: triggerModel.error?.message,
      });
    }
  }, [triggerModel]);

  React.useEffect(() => {
    checkForCalibrationConnected(sensorId);
    form.setFieldsValue({
      step: CalibrationStep.STEP_1,
      timeoutInMs: 30 * 1000,
    });
  }, []);

  // Websocket for remote calibarion
  const token = store.getState().auth.token;
  const socketUrl = process.env.REACT_APP_BASE_URL.replace('http', 'ws')
    .concat('v1/stream?access_token=')
    .concat(token);
  const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  React.useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const onFinish = (store: Store) => {
    const step = store.step;
    const timeoutInMs = store.timeoutInMs;
    calibrateSensorConnected(sensorId, {
      step,
      timeoutInMs,
    });
  };

  const renderForm = () => {
    return (
      <Form form={form} onFinish={onFinish} layout="inline">
        <Form.Item name={'timeoutInMs'} label="Timeout in Ms">
          <InputNumber min={5 * 1000} max={5 * 60 * 1000} step={1000} />
        </Form.Item>

        <Form.Item name={'step'} label="Step">
          <Select>
            <Select.Option value={CalibrationStep.STEP_1}>
              {'Step 1'}
            </Select.Option>
            <Select.Option value={CalibrationStep.STEP_2}>
              {'Step 2'}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={triggerModel.loading}
          >
            {'Send Command'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const getWebsocketTag = () => {
    switch (readyState) {
      case ReadyState.OPEN:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {connectionStatus}
          </Tag>
        );
      case ReadyState.UNINSTANTIATED:
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {connectionStatus}
          </Tag>
        );
      case ReadyState.CONNECTING:
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {connectionStatus}
          </Tag>
        );

      case ReadyState.CLOSING:
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {connectionStatus}
          </Tag>
        );

      case ReadyState.CLOSING:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {connectionStatus}
          </Tag>
        );

      default:
        return <Tag color="default">{connectionStatus}</Tag>;
    }
  };

  const renderWebsocketEventListener = () => {
    return (
      <Card
        title="Websocket Listener"
        type="inner"
        style={{ width: '100%' }}
        extra={getWebsocketTag()}
      >
        <List
          bordered
          renderItem={(message, idx) => (
            <List.Item key={idx}>{message.data}</List.Item>
          )}
          dataSource={messageHistory}
        />
      </Card>
    );
  };

  const renderCalibrationOptions = () => {
    return (
      <Col>
        <Row>{renderForm()}</Row>
        <Row>{renderWebsocketEventListener()}</Row>
      </Col>
    );
  };

  const renderFallback = () => {
    return (
      <Typography>{'Calibration is not supported for this sensor!'}</Typography>
    );
  };

  return (
    <Card title="Remote Calibration" loading={calibrateableModel.loading}>
      {calibrateableModel.result
        ? renderCalibrationOptions()
        : renderFallback()}
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  triggerModel: state.calibration.trigger,
  calibrateableModel: state.calibration.calibrateable,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      calibrateSensorConnected: calibrateSensor,
      checkForCalibrationConnected: checkForCalibration,
    },
    dispatch,
  );
};

export const SensorCalibrationCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorCalibrationCard);
