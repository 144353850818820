import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Button,
  Space,
  Result,
  notification,
  Form,
  Select,
  Switch,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getFarm, updateFarm } from '../../duck/modules/farms';
import { PondsSensorDataTable } from '../pond/pondsSensorDataTable';
import { Empty } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CreatePondForm } from '../pond/createPondForm';
import { PondModel } from '../../model/domain';
import { FarmDataForm } from '../farm/farmDataForm';
import { UpdateFarmCmd } from '../../model/cmds';
import { getLocations } from '../../duck/modules/locations';
import { INT_MAX } from '../../constants';
import { ListLocationsQuery } from '../../model/querys';
import { activateFarm, deactivateFarm } from '../../duck/modules/activation';

const { Option } = Select;
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ farmId: string }>;

const UnconnectedFarmDetail: React.FC<Props> = ({
  getFarmConnect,
  updateFarmConnect,
  getLocationsConnnect,
  farm,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  locationsState,
  activationState,
  activateFarmConnect,
  deactivateFarmConnect,
}) => {
  const { farmId } = match.params;
  const [addPondModalVisible, setAddPondModalVisible] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    getFarmConnect(farmId);
    //getLocationsConnnect({limit: INT_MAX, offset: 0});
  }, []);

  if (error) {
    return (
      <Result status="error" title="Can't load farm" subTitle={error.message} />
    );
  }

  const renderHubspotButton = () => {
    if (farm) {
      return (
        <Button
          type="primary"
          onClick={() => {
            if (farm?.crmCompanyId) {
              window.location.replace(
                `https://app.hubspot.com/contacts/5586633/company/${farm.crmCompanyId}`,
              );
            } else {
              notification.error({
                message: 'No company connected',
              });
            }
          }}
        >
          Got to Hubspot
        </Button>
      );
    }
  };

  const renderGeneralDataCardContent = () => {
    if (farm) {
      return (
        <FarmDataForm
          farm={farm}
          locations={locationsState.list.locations}
          locationsLoading={locationsState.list.loading}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateFarmCmd) => {
            updateFarmConnect(farmId, cmd);
          }}
          onZipCodeChange={(zipCode: string) => {
            const query: ListLocationsQuery = {
              limit: INT_MAX,
              offset: 0,
              zipCodes: [zipCode],
            };
            getLocationsConnnect(query);
          }}
        />
      );
    }
  };

  const renderOrganisationCardContent = () => {
    return (
      <Link to={`/organisations/${farm?.organisation.id}`}>
        {farm?.organisation.name}
      </Link>
    );
  };

  const renderPondsCardContent = () => {
    if (farm?.ponds) {
      const ponds = farm.ponds;
      if (ponds.length === 0) {
        return (
          <Empty description="There are no ponds associated with this farm">
            <Button onClick={() => setAddPondModalVisible(true)} type="primary">
              Add Pond
            </Button>
          </Empty>
        );
      }

      return <PondsSensorDataTable showName ponds={ponds} />;
    }
  };

  const renderAddPondModal = () => {
    return (
      <Modal
        visible={addPondModalVisible}
        onOk={() => setAddPondModalVisible(false)}
        onCancel={() => setAddPondModalVisible(false)}
        footer={null}
        title={'Create a pond'}
      >
        <CreatePondForm
          farmId={farm?.id}
          organisationId={farm?.organisation.id}
          onCreated={(_pond: PondModel) => {
            setAddPondModalVisible(false);
            getFarmConnect(farmId);
          }}
          onError={(_error: Error) => { }}
        />
      </Modal>
    );
  };

  const activationWidget = () => {
    return (
      <Switch
        checked={farm?.isActive}
        checkedChildren={"Active"}
        unCheckedChildren={"Inactive"}
        onClick={() => farm?.isActive ? deactivateFarmConnect(farmId) : activateFarmConnect(farmId)}
        loading={activationState.loading}
      />
    );
  }

  const enabledWidget = () => {
    return (
      <Switch
        checked={farm?.enabled}
        checkedChildren={"Enabled"}
        unCheckedChildren={"Disabled"}
        onClick={() => farm?.enabled ? updateFarmConnect(farmId, { enabled: false }) : updateFarmConnect(farmId, { enabled: true })}
        loading={updating}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{enabledWidget()}</Col>
              <Col>{activationWidget()}</Col>
              <Col>{renderHubspotButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Organisation Data" loading={loading}>
              {renderOrganisationCardContent()}
            </Card>
          </Col>
          <Col span={24}>
            <Card
              title="Ponds"
              loading={loading}
              extra={
                farm &&
                farm?.ponds.length > 0 && (
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => setAddPondModalVisible(true)}
                    >
                      Add Pond
                    </Button>
                  </Space>
                )
              }
            >
              {renderAddPondModal()}
              {renderPondsCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  farm: state.farms.detail.farm,
  loading: state.farms.detail.loading,
  error: state.farms.detail.error,
  updating: state.farms.detail.updating,
  updateError: state.farms.detail.updateError,
  updateSuccess: state.farms.detail.updateSuccess,
  locationsState: state.locations,
  activationState: state.activation.farm,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFarmConnect: getFarm,
      updateFarmConnect: updateFarm,
      getLocationsConnnect: getLocations,
      activateFarmConnect: activateFarm,
      deactivateFarmConnect: deactivateFarm,
    },
    dispatch,
  );
};

export const FarmDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFarmDetail);
