import {
  Button,
  Col,
  Descriptions,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import {
  getOrganisationSensors,
  removeSensorFromOrganisation,
} from '../../duck/modules/sensing';
import { SensorModel } from '../../model/domain';
import { SensorType } from '../../model/enums';
import { CreateSensorForm } from '../sensor/createSensorForm';
import { getSensorNameToType } from '../../util';
import { functionalColor } from '../../colors';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
  };

const UnconnectedOrganisationSensorsList: React.FC<Props> = ({
  organisationId,
  sensors,
  loading,
  getOrganisationSensorsConnect,
  removeSensorFromOrganisationConnect,
  removeSensorState,
}) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    getOrganisationSensorsConnect(organisationId);
  }, []);

  React.useEffect(() => {
    if (removeSensorState.success === true) {
      notification.success({
        message: 'Sensor removed',
      });
    }
  }, [removeSensorState.success]);

  React.useEffect(() => {
    if (removeSensorState.error) {
      notification.error({
        message: 'Error while removing the sensor',
        description: removeSensorState.error?.message,
      });
    }
  }, [removeSensorState.error]);

  const renderSensor = (sensor: SensorModel) => {
    return (
      <List.Item>
        <List.Item.Meta
          description={
            <Descriptions bordered column={1}>
              {sensor.name && (
                <Descriptions.Item
                  label={
                    <Space>
                      <Typography.Text>{'Name'}</Typography.Text>
                      {sensor.name && sensor.name.inSync && (
                        <Tooltip title={'Synchronized'}>
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      )}
                      {sensor.name && !sensor.name.inSync && (
                        <Tooltip title={`Syncing...`}>
                          <WarningOutlined
                            style={{
                              color: functionalColor.warning,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  }
                >
                  <Typography.Text strong>
                    {sensor.name ? sensor.name.actual : ''}
                  </Typography.Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={'ID'}>
                <Link to={`/sensors/${sensor.id}`}>{sensor.id}</Link>
              </Descriptions.Item>

              <Descriptions.Item label={'Type'}>
                {getSensorNameToType(sensor.type)}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <Typography.Text>{'Socket'}</Typography.Text>
                    {sensor.deviceSocket.inSync ? (
                      <Tooltip title={'Synchronized'}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Syncing...`}>
                        <WarningOutlined
                          style={{
                            color: functionalColor.warning,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Space>
                }
              >
                <Typography.Text>
                  {sensor.deviceSocket.inSync
                    ? sensor.deviceSocket.actual
                    : sensor.deviceSocket.requested}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <Typography.Text>{'Mode'}</Typography.Text>
                    {sensor.deviceSocket.inSync ? (
                      <Tooltip title={'Synchronized'}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Syncing...`}>
                        <WarningOutlined
                          style={{
                            color: functionalColor.warning,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Space>
                }
              >
                <Typography.Text>
                  {sensor.mode.inSync
                    ? sensor.mode.actual
                    : sensor.mode.requested}
                </Typography.Text>
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text>{'Actions'}</Typography.Text>}
              >
                <Space>
                  <Popconfirm
                    title={
                      'Are you sure to remove this sensor from the organisation?'
                    }
                    onConfirm={() =>
                      removeSensorFromOrganisationConnect(
                        organisationId,
                        sensor.id,
                      )
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="default">
                      Remove
                    </Button>
                  </Popconfirm>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </List.Item>
    );
  };

  const renderListHeader = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Text strong>Sensors</Typography.Text>
        </Col>
        <Col>
          <Button onClick={() => setVisible(true)} type="link">
            Add Sensor
          </Button>
        </Col>
        <Modal
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          title={'Create sensor for organisation'}
        >
          <CreateSensorForm
            organisationId={organisationId}
            onCreated={(_sensor: SensorModel) => {
              setVisible(false);
            }}
            onError={(_error: Error) => {
              // setVisible(false);
            }}
          />
        </Modal>
      </Row>
    );
  };

  return (
    <List
      loading={loading}
      bordered
      header={renderListHeader()}
      itemLayout="horizontal"
      dataSource={sensors}
      grid={{
        gutter: 8,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      renderItem={(sensor) => renderSensor(sensor)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.sensing.organisationSensors.loading,
  sensors: state.sensing.organisationSensors.sensors,
  removeSensorState: state.sensing.removeSensor,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationSensorsConnect: getOrganisationSensors,
      removeSensorFromOrganisationConnect: removeSensorFromOrganisation,
    },
    dispatch,
  );
};

export const OrganisationSensorsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationSensorsList);
