import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { SensorCapModel } from '../../model/domain';
import { UpdateSensorCapCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';

type Props = {
  sensorCap: SensorCapModel;
  onUpdate: (cmd: UpdateSensorCapCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const SensorCapDataForm: React.FC<Props> = ({
  sensorCap,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...sensorCap,
    });
  }, [sensorCap]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Sensor Cap updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Sensor Cap',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { serialNo, k0, k1, k2, k3, k4, k5, k6, k7, k8 } = values;
    const cmd: UpdateSensorCapCmd = {};

    if (serialNo != '' && serialNo !== sensorCap.serialNo) {
      cmd.serialNo = serialNo;
    }
    if (k0 !== sensorCap.k0) {
      cmd.k0 = k0;
    }
    if (k1 !== sensorCap.k1) {
      cmd.k1 = k1;
    }
    if (k2 !== sensorCap.k2) {
      cmd.k2 = k2;
    }
    if (k3 !== sensorCap.k3) {
      cmd.k3 = k3;
    }
    if (k4 !== sensorCap.k4) {
      cmd.k4 = k4;
    }
    if (k5 !== sensorCap.k5) {
      cmd.k5 = k5;
    }
    if (k6 !== sensorCap.k6) {
      cmd.k6 = k6;
    }
    if (k7 !== sensorCap.k7) {
      cmd.k7 = k7;
    }
    if (k8 !== sensorCap.k8) {
      cmd.k8 = k8;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && sensorCap) {
      cmd.serialNo = sensorCap.serialNo;
      cmd.k0 = sensorCap.k0;
      cmd.k1 = sensorCap.k1;
      cmd.k2 = sensorCap.k2;
      cmd.k3 = sensorCap.k3;
      cmd.k4 = sensorCap.k4;
      cmd.k5 = sensorCap.k5;
      cmd.k6 = sensorCap.k6;
      cmd.k7 = sensorCap.k7;
      cmd.k8 = sensorCap.k8;
    }

    if (Object.keys(cmd).length > 0) {
      onUpdate(cmd);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Serial Number"
        name="serialNo"
        rules={[{ required: true }]}
      >
        <Input placeholder="YL5021091316" />
      </Form.Item>

      <Form.Item label="K0 / L0" name="k0" rules={[{ required: true }]}>
        <Input placeholder="-0.00221376346707775" type={'number'} />
      </Form.Item>

      <Form.Item label="K1 / L1" name="k1" rules={[{ required: true }]}>
        <Input placeholder="0.274767457758179" type={'number'} />
      </Form.Item>

      <Form.Item label="K2 / L2" name="k2" rules={[{ required: true }]}>
        <Input placeholder="-0.0323236846371235" type={'number'} />
      </Form.Item>

      <Form.Item label="K3 / L3" name="k3" rules={[{ required: true }]}>
        <Input placeholder="0.0167768910454112" type={'number'} />
      </Form.Item>

      <Form.Item label="K4 = 0" name="k4" rules={[{ required: true }]}>
        <Input placeholder="0" type={'number'} />
      </Form.Item>

      <Form.Item label="K5 / T1" name="k5" rules={[{ required: true }]}>
        <Input placeholder="0.0139881840528325" type={'number'} />
      </Form.Item>

      <Form.Item label="K6 / T2" name="k6" rules={[{ required: true }]}>
        <Input placeholder="-0.0000303095585006093" type={'number'} />
      </Form.Item>

      <Form.Item label="K7 / T0" name="k7" rules={[{ required: true }]}>
        <Input placeholder="25" type={'number'} />
      </Form.Item>

      <Form.Item label="K8 / TanZero" name="k8" rules={[{ required: true }]}>
        <Input placeholder="2.280849" type={'number'} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Sensor Cap
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
