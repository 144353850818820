import React from 'react';
import {
  Table,
  Typography,
  Tooltip,
  Space,
  Tag,
  Empty,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import {
  PondModel,
  SensorModel,
} from '../../model/domain';
import moment from 'moment';
import { functionalColor } from '../../colors';
import {
  getColorForSensorValue,
  getDayOfCultivation,
  getShortNameToAeration,
  SensorValueType,
} from '../../util';
import { SimplePondModel } from '../../model/simple';

const { Column, ColumnGroup } = Table;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    ponds: SimplePondModel[];
    showStatus?: boolean;
    showOrganisation?: boolean;
    showFarm?: boolean;
    showName?: boolean;
    loading?: boolean;
    pagination?: any;
    showSalinity?: boolean;
    hideUnusedColumns?: boolean;
    showCycleDoC?: boolean;
    showAeration?: boolean;
  };

const isDataTooOld = (sensor: SensorModel) => {
  return false;
  const dataTimestamp = moment(sensor.lastMeasurementTimestamp);
  return dataTimestamp.isBefore(moment().subtract(1, 'days'));
};

const UnconnectedPondsSensorDataTable: React.FC<Props> = ({
  ponds,
  showStatus,
  showOrganisation,
  showFarm,
  showName,
  hideUnusedColumns,
  showCycleDoC,
  showAeration,
  loading,
  pagination,
}) => {
  const hasYDS = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'YDS'))
    : true;
  const hasSAP = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'SAP'))
    : true;
  const hasSES = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'SES'))
    : true;
  const hasYSS = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'YSS'))
    : true;
  const hasIDS = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'IDS'))
    : true;
  const hasNDP = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'NDP'))
    : true;
  const hasLUX = hideUnusedColumns
    ? !!ponds.find((p) => p.sensors.find((s) => s.type === 'LUX'))
    : true;

  if (!loading && !hasYDS && !hasSAP && !hasSES && !hasIDS && !hasNDP && !hasLUX && !showName) {
    return <Empty description="There is no sensor data!" />;
  }

  return (
    <Table
      rowKey="id"
      pagination={false}
      loading={loading}
      dataSource={ponds.sort((p1: SimplePondModel, p2: SimplePondModel) => {
        const nameOfPond1 = (p1.name && p1.name) || '';
        const nameOfPond2 = (p2.name && p2.name) || '';
        return nameOfPond1.localeCompare(nameOfPond2);
      })}
      scroll={{ x: true }}
      bordered
    >
      {showStatus && (
        <Column
          align="center"
          title="Enabled"
          render={(pond) => {
            if (pond.enabled) {
              return <Typography style={{ color: functionalColor.success }} >{"✓"}</Typography>
            }
            return <Typography style={{ color: functionalColor.error }}  >{"✘"}</Typography>
          }}
        />
      )}
      {showStatus && (
        <Column
          align="center"
          title="BI"
          render={(pond) => {
            if (pond.isActive === true) {
              return <Tag color={functionalColor.success}>Active</Tag>;
            } else if (pond.isActive === false) {
              return <Tag color={functionalColor.error}>Inactive</Tag>;
            }
            return <Tag color={functionalColor.warning}>NULL</Tag>;
          }}
        />
      )}
      {showName && (
        <Column
          align="center"
          title="Pond"
          render={(pond) => (
            <Link key={pond.id} to={`/ponds/${pond.id}`}>
              {pond.name || pond.id}
            </Link>
          )}
        />
      )}
      {/* {showName && (
        <Column
          align="left"
          title="Pond"
          render={(pond) => (
            <Typography>{pond.name}</Typography>

          )}
        />
      )} */}

      {showCycleDoC && (
        <Column
          align="left"
          title="DoC"
          render={(pond: SimplePondModel) => {
            if (pond.cycle) {
              return (
                <Link key={pond.cycle.id} to={`/cycles/${pond.cycle.id}`}>
                  {getDayOfCultivation(pond.cycle.startTimestamp)}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      )}
      {showAeration && (
        <Column
          align="center"
          title="Automation"
          render={(pond: SimplePondModel) => {
            if (pond.aeration) {
              return (
                <Typography>{`${getShortNameToAeration(
                  pond.aeration.automation,
                )}`}</Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      )}
      {
        <Column
          align="center"
          title="Salinity (ppt)"
          render={(pond) => {
            if (pond.salinity) {
              return (
                <Tooltip
                  title={
                    pond.salinity.inSync
                      ? 'Synchronized'
                      : `Syncing... ${pond.salinity.requested} ppt`
                  }
                >
                  <Typography
                    style={{
                      color: pond.salinity.inSync
                        ? functionalColor.primaryText
                        : functionalColor.warning,
                    }}
                  >
                    {`${pond.salinity.actual.toFixed(1)}`}
                  </Typography>
                </Tooltip>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      }
      {(hasYDS || hasIDS) && (
        <ColumnGroup align="center" title="DO-Sensor">
          <Column
            align="center"
            title="DO (ppm)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter(
                        (sensor: SensorModel) =>
                          sensor.type === 'YDS' || sensor.type == 'IDS',
                      )
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.DO_VALUE_PPM,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.doPPM,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.doPPM?.toFixed(
                                    2,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="DO (%)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter(
                        (sensor: SensorModel) =>
                          sensor.type === 'YDS' || sensor.type == 'IDS',
                      )
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.DO_VALUE_PROCENT,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.doPerc,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.doPerc?.toFixed(
                                    0,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Temperatur (°C)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter(
                        (sensor: SensorModel) =>
                          sensor.type === 'YDS' || sensor.type == 'IDS',
                      )
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.WATER_TEMPERATURE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.tempInC,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.tempInC?.toFixed(
                                    1,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        </ColumnGroup>
      )}
      {hasSAP && (
        <ColumnGroup align="center" title="PH-1000A">
          <Column
            align="center"
            title="pH"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'SAP')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.PH_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.pH,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.pH?.toFixed(1)}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        </ColumnGroup>
      )}

      {hasNDP && (
        <ColumnGroup align="center" title="PH-1000D">
          <Column
            align="center"
            title="pH"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'NDP')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.PH_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.pH,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.pH?.toFixed(1)}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        </ColumnGroup>
      )}

      {hasLUX && (
        <ColumnGroup align="center" title="LUX sensor">
          <Column
            align="center"
            title="LUX"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'LUX')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.LUX_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.lux,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.lux?.toFixed(1)}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Humidity (%)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'LUX')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.HUMIDITY_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.humidityInPerc,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.humidityInPerc?.toFixed(1)}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Temperature (°C)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'LUX')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.LUX_TEMPERATURE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.luxTempInC,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.luxTempInC?.toFixed(1)}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        </ColumnGroup>
      )}


      {hasSES && (
        <ColumnGroup align="center" title="SES">
          <Column
            align="center"
            title="EC (A)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'SES')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.AMPERE_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.ecInA,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.ecInA?.toFixed(
                                    2,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="EP (kW)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'SES')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.KILOWATT_VALUE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.epInKW,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.epInKW?.toFixed(
                                    2,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        </ColumnGroup>
      )}
      {hasYSS && (
        <ColumnGroup align="center" title="YSS">
          <Column
            align="center"
            title="Salinity (ppt)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'YSS')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.WATER_SALINITY,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.salPPT,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.salPPT?.toFixed(
                                    1,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Temperature (°C)"
            render={(pond) => {
              if (pond && pond.sensors && pond.sensors) {
                return (
                  <Space direction="vertical">
                    {pond.sensors
                      .filter((sensor: SensorModel) => sensor.type === 'YSS')
                      .sort((s1: SensorModel, s2: SensorModel) => s1.id < s2.id)
                      .map((sensor: SensorModel) => {
                        if (
                          !isDataTooOld(sensor) &&
                          sensor.lastMeasurement &&
                          sensor.lastMeasurementTimestamp
                        ) {
                          return (
                            <Tooltip
                              key={sensor.id}
                              title={`${moment(
                                sensor.lastMeasurementTimestamp,
                              ).fromNow()}: ${moment(
                                sensor.lastMeasurementTimestamp,
                              ).format('DD/MM - HH:mm')}`}
                            >
                              <Link to={`/sensors/${sensor.id}`}>
                                <Typography
                                  style={{
                                    color: getColorForSensorValue(
                                      SensorValueType.WATER_TEMPERATURE,
                                      sensor.lastMeasurementTimestamp,
                                      sensor.lastMeasurement.tempInC,
                                    ),
                                  }}
                                >
                                  {`${sensor.lastMeasurement.tempInC?.toFixed(
                                    1,
                                  )}`}
                                </Typography>
                              </Link>
                            </Tooltip>
                          );
                        }
                      })}
                  </Space>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />




        </ColumnGroup>
      )}
      {showOrganisation && (
        <Column
          align="center"
          title="Organisation"
          render={(pond) => {
            if (pond.organisation) {
              return (
                <Link
                  key={pond.id}
                  to={`/organisations/${pond.organisation.id}`}
                >
                  {pond.organisation.name}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      )}
      {showFarm && (
        <Column
          align="center"
          title="Farm"
          render={(pond: PondModel) => {
            return (
              <Link key={pond.id} to={`/farms/${pond.farm.id}`}>
                {pond.farm.name}
              </Link>
            );
          }}
        />
      )}
    </Table>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const PondsSensorDataTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondsSensorDataTable);
