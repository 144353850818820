import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { pumpApi } from '../../api/pumpApi';
import {
  CREATE_PUMP,
  CREATE_PUMP_FAILED,
  CREATE_PUMP_SUCCESS,
  FETCH_PUMP,
  FETCH_PUMP_FAILED,
  FETCH_PUMP_SUCCESS,
  FETCH_PUMPS,
  FETCH_PUMPS_FAILED,
  FETCH_PUMPS_SUCCESS,
  UPDATE_PUMP,
  UPDATE_PUMP_FAILED,
  UPDATE_PUMP_SUCCESS,
} from '../../constants';
import { CreatePumpCmd, UpdatePumpCmd } from '../../model/cmds';
import { ListPumpsQuery, ListPumpsQueryModel } from '../../model/querys';
import { PumpsState } from '../../model/state';
import { gatewayApi } from '../../api/gatewayApi';
import { refreshPond } from './ponds';
import {
  pumpCreate,
  pumpCreated,
  pumpCreateFailed,
  pumpFetch,
  pumpFetched,
  pumpFetchFailed,
  PumpsAction,
  pumpsFetch,
  pumpsFetched,
  pumpsFetchFailed,
  pumpUpdate,
  pumpUpdated,
  pumpUpdateFailed,
} from '../actions';

export function createPump(
  cmd: CreatePumpCmd,
  gatewayId?: string,
  pondId?: string,
) {
  return async (dispatch: Dispatch<PumpsAction, {}, any>) => {
    try {
      dispatch(pumpCreate());
      let newPump = await pumpApi.createPump(cmd);

      // add pump to gateway
      if (gatewayId) {
        let gateway = await gatewayApi.addPumpToGateway(gatewayId, {
          pumpId: newPump.id,
        });

        // check if gateway is syncronized
        for (let tryNo = 0; tryNo < 10; tryNo++) {
          // new aeator added to gateway
          if (
            gateway.pumps.inSync &&
            gateway.pumps.actual.find((a) => a.id === newPump.id)
          ) {
            // add aertor to pond
            console.warn('pump attached to gateway');
            if (pondId) {
              newPump = await pumpApi.updatePump(newPump.id, {
                pondId,
              });
              // check if pond is set for aeator
              if (newPump.pond.inSync && newPump.pond.actual.id === pondId) {
                console.warn('pump attached to pond');
                dispatch(refreshPond(pondId));
                break;
              } else {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            }
          } else {
            // wait 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            // refetch gateway info
            gateway = await gatewayApi.fetchGateway(gatewayId);
          }
        }
      }

      dispatch(pumpCreated(newPump));
    } catch (e) {
      dispatch(pumpCreateFailed(e));
    }
  };
}

export function getPump(id: string) {
  return async (dispatch: Dispatch<PumpsAction, {}, any>) => {
    try {
      dispatch(pumpFetch());
      const pump = await pumpApi.getPump(id);
      dispatch(pumpFetched(pump));
    } catch (e) {
      dispatch(pumpFetchFailed(e));
    }
  };
}

export function getPumps(query: ListPumpsQuery) {
  return async (dispatch: Dispatch<PumpsAction, {}, any>) => {
    try {
      dispatch(pumpsFetch(query));
      const queryModel: ListPumpsQueryModel = await pumpApi.listPumps(query);
      // fetch details for each received pump
      const pumps = await Promise.all(
        queryModel.pumps.map((pump) => pumpApi.getPump(pump.id)),
      );
      dispatch(pumpsFetched(queryModel, pumps));
    } catch (e) {
      dispatch(pumpsFetchFailed(e));
    }
  };
}

export function updatePump(id: string, cmd: UpdatePumpCmd) {
  return async (dispatch: Dispatch<PumpsAction, {}, any>) => {
    try {
      dispatch(pumpUpdate());
      const pump = await pumpApi.updatePump(id, cmd);
      dispatch(pumpUpdated(pump));
      dispatch(getPump(id));
    } catch (e) {
      dispatch(pumpUpdateFailed(e));
    }
  };
}

export default function pumpsReducer(
  state: PumpsState = {
    create: {
      loading: false,
      error: null,
      pump: null,
    },
    list: {
      loading: false,
      error: null,
      pumps: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      pump: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: PumpsAction,
): PumpsState {
  switch (action.type) {
    case CREATE_PUMP:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, pump: null },
      };
    case CREATE_PUMP_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          pump: action.payload,
        },
      };
    case CREATE_PUMP_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          pump: null,
        },
      };
    case FETCH_PUMP:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          pump: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_PUMP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          pump: action.payload,
        },
      };
    case FETCH_PUMP_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          pump: null,
        },
      };
    case UPDATE_PUMP:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_PUMP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_PUMP_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_PUMPS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          pumps: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_PUMPS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          pumps: action.payload.pumps,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_PUMPS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          pumps: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}
