import React from 'react';

import {
  Badge,
  Card,
  Empty,
  List,
  Statistic,
  Tag,
  Tooltip,
} from 'antd';
import { functionalColor } from '../../colors';
import moment from 'moment';
import { MqttConnectionStatus } from '../../model/enums';
import { Link } from 'react-router-dom';
import { GatewayModel } from '../../model/domain';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';

export const renderGatewayConnectionStatus = (gateway: GatewayModel) => {
  if (gateway.mqttConnectionStatus === MqttConnectionStatus.CONNECTED) {
    return (
      <Tooltip
        title={`Last data: ${moment(
          gateway.mqttConnectionTimestamp,
        ).fromNow()}`}
      >
        <span>
          <Tag color={functionalColor.success}>{'Online'}</Tag>{' '}
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={`Last time connected: ${moment(
        gateway.mqttConnectionTimestamp,
      ).fromNow()}`}
    >
      <span>
        <Tag color={functionalColor.error}>{'Offline'}</Tag>
      </span>
    </Tooltip>
  );
};

export const renderGatewayDetailsLink = (gateway: GatewayModel) => {
  return (
    <Badge
      count={
        gateway.name ? (
          gateway.name?.inSync ? (
            <Tooltip title={'Synchronized'}>
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          ) : (
            <Tooltip title={`Name is changing!`}>
              <WarningOutlined style={{ color: functionalColor.warning }} />
            </Tooltip>
          )
        ) : (
          <Tooltip title={`Gateway has no name!`}>
            <WarningOutlined style={{ color: functionalColor.warning }} />
          </Tooltip>
        )
      }
    >
      <Card size="small">
        <Link key={gateway.id} to={`/gateways/${gateway.id}`}>
          {gateway.name?.actual || gateway.id}
        </Link>
      </Card>
    </Badge>
  );
};

export const renderGatewayPondsList = (gateway: GatewayModel) => {
  return (
    <Badge
      count={
        gateway.ponds.inSync ? (
          <Tooltip title={'Synchronized'}>
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        ) : (
          <Tooltip title={`Ponds are changing!`}>
            <WarningOutlined style={{ color: functionalColor.warning }} />
          </Tooltip>
        )
      }
    >
      <List
        bordered
        dataSource={gateway.ponds?.actual}
        renderItem={(pond) => (
          <List.Item>
            <Link key={gateway.id} to={`/ponds/${pond.id}`}>
              {pond.name || pond.id}
            </Link>
          </List.Item>
        )}
      />
    </Badge>
  );
};

export const renderGatewayOrganisation = (gateway: GatewayModel) => {
  if (gateway.organisation) {
    return (
      <Link key={gateway.id} to={`/organisations/${gateway.organisation.id}`}>
        {gateway.organisation.name}
      </Link>
    );
  }
  return <Empty />;
};

export const renderGatewaySensorsRegistered = (gateway: GatewayModel) => {
  return (
    <Badge
      count={
        gateway.sensors.inSync ? (
          <Tooltip title={'Synchronized'}>
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        ) : (
          <Tooltip title={`Sensors are chaning!`}>
            <WarningOutlined style={{ color: functionalColor.warning }} />
          </Tooltip>
        )
      }
    >
      <List
        bordered
        dataSource={gateway.sensors?.actual}
        renderItem={(sensor) => (
          <List.Item>
            <Link key={sensor.id} to={`/sensors/${sensor.id}`}>
              {sensor.type}
            </Link>
          </List.Item>
        )}
      />
    </Badge>
  );
};

export const renderNumberOfAerators = (gateway: GatewayModel) => {
  return (
    <Badge
      count={
        gateway.aerators.inSync ? (
          <Tooltip title={'Synchronized'}>
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        ) : (
          <Tooltip title={`Aerators are changing!`}>
            <WarningOutlined style={{ color: functionalColor.warning }} />
          </Tooltip>
        )
      }
    >
      <Statistic>{gateway.aerators?.actual?.length}</Statistic>
    </Badge>
  );
};

export const renderNumberOfPumps = (gateway: GatewayModel) => {
  return (
    <Badge
      count={
        gateway.pumps.inSync ? (
          <Tooltip title={'Synchronized'}>
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        ) : (
          <Tooltip title={`Pumps are changing!`}>
            <WarningOutlined style={{ color: functionalColor.warning }} />
          </Tooltip>
        )
      }
    >
      <Statistic value={gateway.pumps?.actual?.length} />
    </Badge>
  );
};

export const renderGatewayMainboardTemperature = (gateway: GatewayModel) => {
  return (
    <Statistic
      precision={1}
      suffix={gateway.omsSensor?.lastMeasurement?.tempInC ? '°C' : ''}
      value={gateway.omsSensor?.lastMeasurement?.tempInC || '-'}
    />
  );
};

export const renderGatewayCpuTemperature = (gateway: GatewayModel) => {
  return (
    <Statistic
      precision={1}
      suffix={gateway.ocsSensor?.lastMeasurement?.tempInC ? '°C' : ''}
      value={gateway.ocsSensor?.lastMeasurement?.tempInC || '-'}
    />
  );
};

export const renderTags = (gateway: GatewayModel) => {
  return (
    <List dataSource={gateway.tags} renderItem={(tag) => <Tag>{tag}</Tag>} />
  );
};
