import React from 'react';
import {
  List,
  Form,
  Select,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import {
  OrganisationModel,
  AccountModel,
} from '../../model/domain';
import { PlusOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import { AddAccountToOrganisationCmd } from '../../model/cmds';
import {
  addMemberToOrganisation,
  getOrganisationMemberOptions,
} from '../../duck/modules/organisations';
import Modal from 'antd/lib/modal/Modal';
import { CreateAccountForm } from '../account/createAccountForm';
import { removeAccountFromOrganisation } from '../../duck/modules/organisations';
import { Link } from 'react-router-dom';
import { getDeepLinkForAppImpersonate } from '../../util';
import { SimpleAccountModel } from '../../model/simple';
import { accountApi } from '../../api/accountApi';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
    organisation: OrganisationModel | null;
  };

const UnconnectedOrganisationMemberForm: React.FC<Props> = ({
  memberAdding,
  memberOptions,
  organisation,
  organisationId,
  getOrganisationMemberOptionsConnect,
  removeAccountFromOrganisationConnect,
  addMemberToOrganisationConnect,
}) => {
  React.useEffect(() => {
    getOrganisationMemberOptionsConnect();
  }, []);

  const [form] = Form.useForm();
  const [accountId, setAccountId] = React.useState('');
  const [visible, setVisible] = React.useState(false);

  const onSubmit = (values: Store) => {
    const { accountId } = values;
    const cmd: AddAccountToOrganisationCmd = {
      accountId,
    };
    if (accountId) {
      addMemberToOrganisationConnect(organisationId, cmd);
      form.resetFields();
    } else {
      setVisible(true);
    }
  };

  const onAccountIdChanged = (accountId: string) => {
    setAccountId(accountId);
    form.setFieldsValue({ accountId });
  };

  const renderMember = (account: any) => {
    let linkText = account.username || account.id;
    const isCreator = account.id === organisation?.creator.id;

    if (isCreator && organisation) {
      return (
        <List.Item
          actions={[
            <a
              target="_blank"
              href={getDeepLinkForAppImpersonate(organisation.creatorToken)}
            >
              <Button type="link">Impersonate</Button>
            </a>,
          ]}
        >
          <Link to={`/accounts/${account.id}`}>{`${linkText} (creator)`} </Link>
        </List.Item>
      );
    }

    return (
      <List.Item
        actions={[
          <Popconfirm
            title="Are you sure you want to remove the user from the organization?"
            onConfirm={() =>
              removeAccountFromOrganisationConnect(organisationId, account.id)
            }
          >
            <Button type="link">Remove</Button>
          </Popconfirm>,
          <Button
            type="link"
            onClick={async () => {
              const tokenModel = await accountApi.fetchToken(account.id);
              const link = getDeepLinkForAppImpersonate(tokenModel.token);
              window.open(link, '_blank', 'noopener,noreferrer');
            }}
          >
            Impersonate
          </Button>,
        ]}
      >
        <Link to={`/accounts/${account.id}`}>{linkText} </Link>
      </List.Item>
    );
  };

  return (
    <>
      <Form form={form} layout={'horizontal'} onFinish={onSubmit}>
        <Form.Item label="Add member" name="accountId">
          <Row gutter={[10, 10]}>
            <Col xs={20} sm={20}>
              <Select
                placeholder="Please select an account"
                allowClear
                onChange={onAccountIdChanged}
                value={accountId ? accountId : undefined}
              >
                {memberOptions.map((account: any) => (
                  <Option key={account.id} value={account.id}>
                    {account.username || account.id}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={4} sm={4}>
              <Button
                loading={memberAdding}
                type="primary"
                htmlType="submit"
                icon={<PlusOutlined />}
              />
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <List
        size="small"
        dataSource={organisation?.members.sort((m1, m2) => {
          if (m1.id === organisation.creator.id) {
            return -1;
          } else if (m2.id === organisation.creator.id) {
            return 1;
          } else if (!m1.name && !m2.name) {
            return 0;
          } else if (m1.name && m2.name) {
            return m1.name > m2.name ? 1 : m2.name > m1.name ? -1 : 0;
          }
          return 0;
        })}
        renderItem={renderMember}
        header={<Typography.Text strong>Members:</Typography.Text>}
      />
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        title={'Create an account'}
      >
        <CreateAccountForm
          onCreated={(account: AccountModel) => {
            onAccountIdChanged(account.id);
            setVisible(false);
          }}
          onError={(_error: Error) => {
            // setVisible(false);
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  memberAdding: state.organisations.members.adding,
  memberOptions: state.organisations.memberOptions.accounts,
  accountCreated: state.accounts.create.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationMemberOptionsConnect: getOrganisationMemberOptions,
      addMemberToOrganisationConnect: addMemberToOrganisation,
      removeAccountFromOrganisationConnect: removeAccountFromOrganisation,
    },
    dispatch,
  );
};

export const OrganisationMemberForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationMemberForm);
