import { Button, Form, Select, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { INT_MAX } from '../../constants';
import { RootState } from '../../duck';
import { addPondToGateway, getGateways } from '../../duck/modules/gateways';
import { usePrevious } from '../_util/hook';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
    pondId: string;
    onSuccess?: () => void;
    onError?: (_error: Error) => void;
  };

const UnconnectedAssignPondToGatewayForm: React.FC<Props> = ({
  onSuccess,
  onError,
  gatewayAddPondState,
  gatewaysListState,
  addPondToGatewayConnect,
  getGatewaysConnect,
  pondId,
  organisationId,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ gatewayAddPondState });

  React.useEffect(() => {
    getGatewaysConnect({
      limit: INT_MAX,
      offset: 0,
      organisationIds: [organisationId],
    });
  }, []);

  const onFinish = (values: Store) => {
    const { gatewayId } = values;
    addPondToGatewayConnect(gatewayId, { pondId });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Gateway" name="gatewayId" rules={[{ required: true }]}>
        <Select placeholder="Select gateway organisation" allowClear>
          {gatewaysListState.gateways.map((gateway) => (
            <Option key={gateway.id} value={gateway.id}>
              {gateway.name?.actual
                ? `${gateway.name?.actual} (${gateway.id})`
                : gateway.id}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={gatewayAddPondState.loading}
          >
            Assing Pond
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  gatewayAddPondState: state.gateways.addPond,
  gatewaysListState: state.gateways.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addPondToGatewayConnect: addPondToGateway,
      getGatewaysConnect: getGateways,
    },
    dispatch,
  );
};

export const AssignPondToGatewayForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAssignPondToGatewayForm);
