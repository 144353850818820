import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Tag,
  Form,
  Badge,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getProducts } from '../../duck/modules/products';
import Column from 'antd/lib/table/Column';
import { SimpleProductModel } from '../../model/simple';
import { functionalColor } from '../../colors';
import { ProductCategory } from '../../model/enums';
import { Option } from 'antd/lib/mentions';
import Checkbox from 'antd/lib/checkbox/Checkbox';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      categories?: ProductCategory[];
      showDeleted?: string;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedProductsResponsiveTable: React.FC<Props> = ({
  products,
  loading,
  error,
  total,
  getProductsConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const [showDeleted, setShowDeleted] = React.useState(
    queryParams?.showDeleted === 'true' || false,
  );
  const [categories, setCategories] = React.useState(
    queryParams?.categories || [],
  );

  React.useEffect(() => {
    getProductsConnect({
      limit,
      offset,
      categories,
      deleted: showDeleted,
    });
    onReloaded();
  }, [offset, limit, reload, categories, showDeleted]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderList = () => {
    return (
      <List
        loading={loading}
        grid={{ gutter: 10, column: 1 }}
        dataSource={products}
        renderItem={(product) => (
          <List.Item>
            <Card>
              <Descriptions
                column={3}
                layout="horizontal"
                colon={true}
                size="small"
                bordered
              >
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Status'}
                    </Typography>
                  }
                >
                  {!product.deleted ? (
                    <Badge color={functionalColor.success} text="Active" />
                  ) : (
                    <Badge color={functionalColor.error} text="Deactivated" />
                  )}
                  <Link key={product.id} to={`/products/${product.id}`}>
                    {product.name != undefined ? product.name : product.id}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Product'}
                    </Typography>
                  }
                >
                  <Link key={product.id} to={`/products/${product.id}`}>
                    {product.name != undefined ? product.name : product.id}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Manufacturer'}
                    </Typography>
                  }
                >
                  {product.manufacturer ? (
                    <Link
                      key={product.id}
                      to={`/manufacturers/${product.manufacturer.id}`}
                    >
                      {product.manufacturer.name || product.manufacturer.id}
                    </Link>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Category'}
                    </Typography>
                  }
                >
                  {product.category ? (
                    <Tag>{product.category}</Tag>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={products}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Status"
          render={(product: SimpleProductModel) => {
            if (!product.deleted) {
              return <Badge color={functionalColor.success} text="Active" />;
            }
            return <Badge color={functionalColor.error} text="Deactivated" />;
          }}
        />
        <Column
          align="center"
          title="Product"
          render={(product: SimpleProductModel) => (
            <Link key={product.id} to={`/products/${product.id}`}>
              {product.name != undefined ? product.name : product.id}
            </Link>
          )}
        />
        <Column
          align="center"
          title="Manufacturer"
          render={(product: SimpleProductModel) => {
            if (product.manufacturer) {
              return (
                <Link
                  key={product.id}
                  to={`/manufacturers/${product.manufacturer.id}`}
                >
                  {product.manufacturer.name || product.manufacturer.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
        <Column
          align="center"
          title="Category"
          render={(product: SimpleProductModel) => {
            if (product.category) {
              return <Tag>{product.category}</Tag>;
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      </Table>
    );
  };

  const renderFilter = () => {
    return (
      <Form
        layout="inline"
        style={{ width: '100%' }}
        initialValues={{
          categories: [...categories],
        }}
      >
        <Form.Item name="showDeleted" valuePropName="showDeleted">
          <Checkbox
            onChange={(e) => {
              const checked = new Boolean(e.target.checked);

              let searchParams = new URLSearchParams(window.location.search);
              searchParams.set('showDeleted', checked.toString());
              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                searchParams.toString();
              window.history.pushState({ path: newurl }, '', newurl);

              setShowDeleted(checked.valueOf());
            }}
            checked={showDeleted}
          >
            {'Show deleted'}
          </Checkbox>
        </Form.Item>

        <Form.Item name="categories" label="Filter by Category">
          <Select
            mode="multiple"
            placeholder="nothing selected"
            value={categories}
            onChange={(data) => {
              let searchParams = new URLSearchParams(window.location.search);
              searchParams.delete('categories');
              data.forEach((d) => searchParams.append('categories', d));

              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                searchParams.toString();
              window.history.pushState({ path: newurl }, '', newurl);

              setCategories(data);
            }}
            allowClear={false}
            style={{ minWidth: 300 }}
          >
            <Select.Option value={ProductCategory.FEED}>{'FEED'}</Select.Option>
            <Select.Option value={ProductCategory.FEED_SUPPLEMENT}>
              {'FEED_SUPPLEMENT'}
            </Select.Option>
            <Select.Option value={ProductCategory.CHEMICAL}>
              {'CHEMICAL'}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Products"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      <div className="hide-on-mobile">{renderTable()}</div>
      <div className="hide-on-desktop">{renderList()}</div>
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  products: state.products.list.products,
  loading: state.products.list.loading,
  error: state.products.list.error,
  total: state.products.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getProductsConnect: getProducts,
    },
    dispatch,
  );
};

export const ProductsResponsiveTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedProductsResponsiveTable);
