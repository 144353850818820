import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  DatePicker,
  Spin,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import {
  getSample,
  updateSample,
} from '../../duck/modules/samples';
import { usePrevious } from '../_util/hook';
import { UpdateSampleCmd } from '../../model/cmds';
import { useState } from 'react';
import moment from 'moment';
import { SampleType } from '../../model/enums';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    sampleId: string;
    onUpdated?: () => void;
    onError?: (error: Error) => void;
  };

const UnconnectedUpdateSampleForm: React.FC<Props> = ({
  getSampleConnect,
  updateSampleConnect,
  onUpdated,
  onError,
  detailState,
  sampleId,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({
    success: detailState.updateSuccess,
    error: detailState.updateError,
  });
  const [formValues, setFormValues] = useState(form.getFieldsValue);

  React.useEffect(() => {
    getSampleConnect(sampleId);
  }, [sampleId]);

  React.useEffect(() => {
    form.resetFields();
  }, [detailState.loading]);

  React.useEffect(() => {
    if (prev?.success === null && detailState.updateSuccess) {
      notification.success({
        message: 'Sample updated',
      });
    }
    if (detailState.updateSuccess && onUpdated) {
      onUpdated();
    }
    form.resetFields();
    setFormValues(form.getFieldsValue);
  }, [detailState.updateSuccess]);

  React.useEffect(() => {
    if (prev?.error === null && detailState.updateError) {
      notification.error({
        message: 'Sample updating error',
        description: detailState.updateError?.message,
      });
      if (detailState.updateError && onError) {
        onError(detailState.updateError);
      }
    }
  }, [detailState.updateError]);

  const onFinish = (values: Store) => {
    const {
      date,
      numberOfShrimpsPerKg,
      weightOfShrimpInGram,
      sizeOfShrimpInCm,
    } = values;
    const timestamp = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    const cmd: UpdateSampleCmd = {
      timestamp,
    };

    const numberOfShrimpsPerKgValue = parseInt(numberOfShrimpsPerKg);
    const sizeOfShrimpInCmValue = parseFloat(sizeOfShrimpInCm);
    const weightOfShrimpInGramValue = parseFloat(weightOfShrimpInGram);

    if (
      !!numberOfShrimpsPerKgValue &&
      detailState.sample?.type === SampleType.SHRIMP_BUCKET
    ) {
      cmd.numberOfShrimpsPerKg = numberOfShrimpsPerKgValue;
    } else if (
      !!sizeOfShrimpInCmValue &&
      detailState.sample?.type === SampleType.SHRIMP_LENGTH
    ) {
      cmd.sizeOfShrimpInCm = sizeOfShrimpInCmValue;
    } else if (
      !!weightOfShrimpInGramValue &&
      detailState.sample?.type === SampleType.SHRIMP_WEIGHT
    ) {
      cmd.weightOfShrimpInGram = weightOfShrimpInGramValue;
    }

    updateSampleConnect(sampleId, cmd);
  };

  if (detailState.loading || detailState.sample == null) {
    return (
      <div>
        <Form form={form} />
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      requiredMark={false}
      onValuesChange={() => setFormValues(form.getFieldsValue())}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        date: moment(detailState.sample?.timestamp),
        numberOfShrimpsPerKg: detailState.sample?.numberOfShrimpsPerKg,
        sizeOfShrimpInCm: detailState.sample?.sizeOfShrimpInCm,
        weightOfShrimpInGram: detailState.sample?.weightOfShrimpInGram,
      }}
    >
      <Form.Item label="Sample date" name="date" rules={[{ required: true }]}>
        <DatePicker
          style={{ width: '100%' }}
          format={'DD / MM / YYYY'}
          disabledDate={(d) => d.isAfter(moment())}
        />
      </Form.Item>

      {detailState.sample.type == SampleType.SHRIMP_BUCKET && (
        <Form.Item
          label="Number of shrimps per kilogram?"
          name="numberOfShrimpsPerKg"
          rules={[
            {
              required:
                !!!form.getFieldValue('sizeOfShrimpInCm') &&
                !!!form.getFieldValue('weightOfShrimpInGram'),
            },
          ]}
        >
          <Input type="number" placeholder="10" />
        </Form.Item>
      )}

      {detailState.sample.type == SampleType.SHRIMP_LENGTH && (
        <Form.Item
          label="How many centimeters was the shrimp?"
          name="sizeOfShrimpInCm"
        >
          <Input type="number" placeholder="13.5" />
        </Form.Item>
      )}

      {detailState.sample.type == SampleType.SHRIMP_WEIGHT && (
        <Form.Item
          label="How many grams did the shrimp weigh?"
          name="weightOfShrimpInGram"
        >
          <Input type="number" placeholder="40" />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={detailState.updating}
          >
            {'Update Sample'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  detailState: state.samples.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSampleConnect: getSample,
      updateSampleConnect: updateSample,
    },
    dispatch,
  );
};

export const UpdateSampleForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedUpdateSampleForm);
