import { Button, Card, Form, Input, Space, } from 'antd';
import { Store } from 'antd/lib/form/interface';
import _ from 'lodash';
import React from 'react';
import { UpdateBatchInformationCmd } from '../../model/cmds';
import { BatchInformation } from '../../model/domain';

type Props = {
  batchInformation?: BatchInformation;
  onUpdate: (cmd: UpdateBatchInformationCmd) => void;
  updating: boolean;
};

export const RenderBatchInformationCard: React.FC<Props> = ({
  batchInformation,
  onUpdate,
  updating,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      number: batchInformation?.number,
      description: batchInformation?.description,
    });
  }, [batchInformation]);

  const onSubmit = (values: Store) => {
    const { number, description } = values;

    const cmd: UpdateBatchInformationCmd = {
      number: number,
      description: description,
    };
    onUpdate(cmd);
  };

  const batchInformationCardContent = () => {
    return (
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Batch Number"
          name="number"
          rules={[{ required: true }]}
        >
          <Input placeholder="Batch number of the controller" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input placeholder="Some description for this batch" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={updating}>
              Update Batch Information
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card
      title="Batch Information"
      style={{ height: '100%' }}
      loading={updating}
    >
      {batchInformationCardContent()}
    </Card>
  );
};
