import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { ProductModel } from '../../model/domain';
import { ProductCategory } from '../../model/enums';
import { CreateProductCmd } from '../../model/cmds';
import { createProduct } from '../../duck/modules/products';
import { getManufacturerOptions } from '../../duck/modules/options';
import { INT_MAX } from '../../constants';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (product: ProductModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateProductForm: React.FC<Props> = ({
  createProductConnect,
  getManufacturerOptionsConnect,
  productCreateState,
  manufacturerOptionsState,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();
  const [category, setCategory] = React.useState<ProductCategory>();

  React.useEffect(() => {
    if (productCreateState.product) {
      notification.success({
        message: 'Product created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(productCreateState.product);
      }
    }
  }, [productCreateState.product]);

  React.useEffect(() => {
    if (productCreateState.error) {
      notification.error({
        message: 'Product creation error',
        description: productCreateState.error?.message,
      });
      if (onError != null) {
        onError(productCreateState.error);
      }
    }
  }, [productCreateState.error]);

  React.useEffect(() => {
    if (category) {
      getManufacturerOptionsConnect({
        limit: INT_MAX,
        offset: 0,
        categories: [category],
      });
    }
  }, [category]);

  const onCategoryChanged = (category: ProductCategory) => {
    setCategory(category);
    form.setFieldsValue({ category });
  };

  const onReset = () => {
    onCategoryChanged(ProductCategory.FEED);
    form.resetFields();
  };

  const onSubmit = (values: Store) => {
    const { name, category, manufacturerId } = values;

    const cmd: CreateProductCmd = {
      name,
      category,
    };

    if (manufacturerId && manufacturerId != '') {
      cmd.manufacturerId = manufacturerId;
    }

    createProductConnect(cmd);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={{
        manufacturerId: '',
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true }, { type: 'string' }]}
      >
        <Input placeholder="Enter product name" type="text" />
      </Form.Item>

      <Form.Item label="Category" name="category" rules={[{ required: true }]}>
        <Select
          placeholder="Select product category"
          allowClear
          onChange={onCategoryChanged}
        >
          <Select.Option value={ProductCategory.FEED}>{'FEED'}</Select.Option>
          <Select.Option value={ProductCategory.FEED_SUPPLEMENT}>
            {'FEED_SUPPLEMENT'}
          </Select.Option>
          <Select.Option value={ProductCategory.CHEMICAL}>
            {'CHEMICAL'}
          </Select.Option>
        </Select>
      </Form.Item>

      {form.getFieldValue('category') && manufacturerOptionsState.model && (
        <Form.Item
          name="manufacturerId"
          label="Manufacturer"
          rules={[{ required: false, message: 'Selected manufacturer' }]}
        >
          <Select
            placeholder="Select Manufacturer"
            options={
              manufacturerOptionsState.model.manufacturers.map((option) => {
                return {
                  label: `${option.name}`,
                  value: option.id,
                };
              }) || []
            }
          />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={productCreateState.loading}
          >
            {'Create Product'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  productCreateState: state.products.create,
  manufacturerOptionsState: state.options.manufacturerOptions,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createProductConnect: createProduct,
      getManufacturerOptionsConnect: getManufacturerOptions,
    },
    dispatch,
  );
};

export const CreateProductForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateProductForm);
