import { Button, Form, notification, Select, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { OrganisationModel } from '../../model/domain';
import { INT_MAX } from '../../constants';

import {
  addSmartSensorToOrganisation,
} from '../../duck/modules/organisations';
import { getSmartSensors } from '../../duck/modules/smartSensors';
import { SimpleSmartSensorModel } from '../../model/simple';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onAdded?: (_: OrganisationModel) => void;
    onError?: (error: Error) => void;
    organisationId: string;
  };

const UnconnectedAddSmartSensorToOrganisationForm: React.FC<Props> = ({
  getSmartSensorsConnect,
  addSmartSensorToOrganisationConnect,
  organisationId,
  smartSensorListState,
  smartSensorAddState,
  smartSensorRemoveState,
  onAdded,
  onError,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [smartSensorRemoveState.organisation]);

  const fetchData = () => {
    getSmartSensorsConnect({
      limit: INT_MAX,
      offset: 0,
      organisationIds: 'null',
    });
  };

  React.useEffect(() => {
    if (smartSensorAddState.organisation) {
      notification.success({
        message: 'Smart Sensor added',
      });
      form.resetFields();
      if (onAdded) {
        onAdded(smartSensorAddState.organisation);
      }
      fetchData();
    }
  }, [smartSensorAddState.organisation]);

  React.useEffect(() => {
    if (smartSensorAddState.error) {
      notification.error({
        message: 'Error while adding Smart Sensor',
        description: smartSensorAddState.error?.message,
      });
      if (onError) {
        onError(smartSensorAddState.error);
      }
    }
  }, [smartSensorAddState.error]);

  const onSubmit = (values: Store) => {
    const { smartSensorId } = values;
    addSmartSensorToOrganisationConnect(organisationId, smartSensorId);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        label="Smart Sensor"
        name="smartSensorId"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select smart sensor"
          allowClear
          showSearch
          loading={smartSensorListState.loading}
        >
          {smartSensorListState.smartSensors.map(
            (smartSensor: SimpleSmartSensorModel) => (
              <Option key={smartSensor.id} value={smartSensor.id}>
                {smartSensor.name || smartSensor.id}
              </Option>
            ),
          )}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={smartSensorAddState.loading}
          >
            {'Add Smart Sensor'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  smartSensorListState: state.smartSensors.list,
  smartSensorAddState: state.organisations.addSmartSensor,
  smartSensorRemoveState: state.organisations.removeSmartSensor,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSmartSensorsConnect: getSmartSensors,
      addSmartSensorToOrganisationConnect: addSmartSensorToOrganisation,
    },
    dispatch,
  );
};

export const AddSmartSensorToOrganisationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAddSmartSensorToOrganisationForm);
