import {
  Button,
  Card,
  Empty,
  List,
  Tooltip,
  Row,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { FarmModel, PondModel } from '../../model/domain';
import { CreatePondForm } from '../pond/createPondForm';
import { PondsSensorDataTable } from '../pond/pondsSensorDataTable';
import { PlusOutlined } from '@ant-design/icons';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    farm: FarmModel;
    organisationId: string;
  };

const UnconnectedOrganisationFarmsListItem: React.FC<Props> = ({
  farm,
  organisationId,
}) => {
  const [visible, setVisible] = React.useState(false);

  const renderPondTable = () => {
    if (farm.ponds.length === 0) {
      return (
        <Empty description="There are no ponds associated with this farm">
          <Button onClick={() => setVisible(true)} type="primary">
            Add Pond
          </Button>
        </Empty>
      );
    }

    return <PondsSensorDataTable showName ponds={farm.ponds} />;
  };

  const renderFarmCard = () => {
    return (
      <Card
        title={
          <Row justify="space-between">
            <Link to={`/farms/${farm.id}`}>
              <Button type="link">{farm.name}</Button>
            </Link>
            <Tooltip title="Add pond to farm">
              <Button
                type="ghost"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setVisible(true)}
              >
                Pond
              </Button>
            </Tooltip>
          </Row>
        }
      >
        {renderPondTable()}
      </Card>
    );
  };

  const renderAddPondModal = () => {
    return (
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        title={'Add pond to farm'}
      >
        <CreatePondForm
          farmId={farm.id}
          organisationId={organisationId}
          onCreated={(pond: PondModel) => {
            console.warn('CREATED', JSON.stringify(pond));
            setVisible(false);
          }}
          onError={(_error: Error) => {
            console.warn('ERROR', JSON.stringify(_error));
          }}
        />
      </Modal>
    );
  };

  return (
    <List.Item>
      {renderFarmCard()}
      {renderAddPondModal()}
    </List.Item>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const OrganisationFarmsListItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationFarmsListItem);
