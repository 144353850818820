import {
  Card,
  Col,
  Layout,
  Result,
  Row,
  Typography,
  Descriptions,
  Tooltip,
  Space,
  Alert,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../duck';
import { getSensor, updateSensor } from '../../duck/modules/sensing';
import { SensorAggregations } from '../sensor/sensorAggregations';
import { getSensorNameToType } from '../../util';
import moment from 'moment';
import { SensorEntityForm } from '../sensor/sensorEntityForm';
import { UpdateSensorCmd } from '../../model/cmds';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { SensorType } from '../../model/enums';
import { SensorCalibrationLogs } from '../sensor/sensorCalibrationLogs';
import { getGateway } from '../../duck/modules/gateways';
import { getSensorCapOptions } from '../../duck/modules/options';
import { INT_MAX } from '../../constants';
import { SensorCalibrationCard } from '../sensor/sensorCalibrationCard';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ sensorId: string }>;

const UnconnectedSensorDetail: React.FC<Props> = ({
  sensor,
  match,
  loading,
  error,
  getSensorConnect,
  updateSensorConnect,
  getGatewayConnect,
  updating,
  updateError,
  updateSuccess,
  location,
  gatewayState,
  getSensorCapOptionsConnect,
  sensorCapOptionsState,
}) => {
  const { sensorId } = match.params;
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    getSensorConnect(sensorId);
  }, []);

  React.useEffect(() => {
    if (sensor != null && sensor.gateway != null && sensor.gateway.id) {
      getGatewayConnect(sensor.gateway.id);
    }
    if (sensor != null) {
      getSensorCapOptionsConnect({ limit: INT_MAX, offset: 0 });
    }
  }, [sensor]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load sensor data"
        subTitle={error.message}
      />
    );
  }

  const sensorsWithCalibrationData = [
    SensorType.NDP,
    SensorType.YDS,
    SensorType.SAP,
    SensorType.YDS,
  ];

  const renderFormCard = () => {
    const smartSensorsFromGateway = gatewayState.gateway?.smartSensors.actual;

    return (
      <Card title={'General Data'} loading={loading}>
        {sensor && (
          <SensorEntityForm
            sensor={sensor}
            updating={updating}
            updateSuccess={updateSuccess}
            updateError={updateError}
            onUpdate={(cmd: UpdateSensorCmd) => {
              updateSensorConnect(sensorId, cmd);
            }}
            smartSensorOptions={smartSensorsFromGateway || []}
            sensorCapOptions={
              sensorCapOptionsState.model != null
                ? sensorCapOptionsState.model.sensorCapValues
                : []
            }
          />
        )}
      </Card>
    );
  };

  const renderGeneralDataCard = () => {
    return (
      <Card title={'Measurement Data'} loading={loading}>
        {sensor && (
          <Descriptions bordered column={1}>
            {sensor.type == SensorType.OCS || sensor.type == SensorType.OMS ? (
              <Descriptions.Item label="Sensor">
                <Space>
                  <Typography>{`${getSensorNameToType(
                    sensor.type,
                  )}: `}</Typography>
                  <Link to={`/gateways/${sensor.gateway?.id}`}>
                    {`${sensor.gateway?.name}`}
                  </Link>
                </Space>
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Sensor">
                <Typography>{`${getSensorNameToType(sensor.type)}`}</Typography>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Last Measurment">
              {sensor.lastMeasurement && (
                <Descriptions bordered column={1}>
                  {sensor.lastMeasurement.doRawPerc !== undefined && (
                    <Descriptions.Item label="Raw DO (%)">
                      <Typography>{`${sensor.lastMeasurement.doRawPerc} %`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.doPerc !== undefined && (
                    <Descriptions.Item label="DO (%)">
                      <Typography>{`${sensor.lastMeasurement.doPerc} %`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.doPPM !== undefined && (
                    <Descriptions.Item label="DO (ppm)">
                      <Typography>{`${sensor.lastMeasurement.doPPM} ppm`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.tempInC !== undefined && (
                    <Descriptions.Item label="Temperature">
                      <Typography>{`${sensor.lastMeasurement.tempInC} °C`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.pHRawInMv !== undefined && (
                    <Descriptions.Item label="Raw PH">
                      <Typography>{`${sensor.lastMeasurement.pHRawInMv} mV`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.pH !== undefined && (
                    <Descriptions.Item label="PH">
                      <Typography>{`${sensor.lastMeasurement.pH}`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.ecInA !== undefined && (
                    <Descriptions.Item label="EC">
                      <Typography>{`${sensor.lastMeasurement.ecInA} A`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.epInKW !== undefined && (
                    <Descriptions.Item label="EP">
                      <Typography>{`${sensor.lastMeasurement.epInKW} kW`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.rawCondInMs !== undefined && (
                    <Descriptions.Item label="Raw Conductivity">
                      <Typography>{`${sensor.lastMeasurement.rawCondInMs} µS/cm`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.condInMs !== undefined && (
                    <Descriptions.Item label="Conductivity">
                      <Typography>{`${sensor.lastMeasurement.condInMs} µS/cm`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.salPPT !== undefined && (
                    <Descriptions.Item label="Salinity">
                      <Typography>{`${sensor.lastMeasurement.salPPT} ppt`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.lux !== undefined && (
                    <Descriptions.Item label="LUX">
                      <Typography>{`${sensor.lastMeasurement.lux}`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.humidityInPerc !== undefined && (
                    <Descriptions.Item label="Humidity">
                      <Typography>{`${sensor.lastMeasurement.humidityInPerc} %`}</Typography>
                    </Descriptions.Item>
                  )}
                  {sensor.lastMeasurement.salPPT !== undefined && (
                    <Descriptions.Item label="Temperature">
                      <Typography>{`${sensor.lastMeasurement.luxTempInC} °C`}</Typography>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Last Measurment Timestamp">
              <Tooltip
                placement="left"
                title={moment(sensor.lastMeasurementTimestamp).fromNow()}
              >
                <Typography>
                  {moment(sensor.lastMeasurementTimestamp).format('llll')}
                </Typography>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderHierarchyInformationCard = () => {
    if (sensor?.type == SensorType.OCS || sensor?.type == SensorType.OMS) {
      return;
    }
    return (
      <Card title={'Hierarchy'} loading={loading}>
        {sensor && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Organisation">
              {sensor.organisation ? (
                <Link to={`/organisations/${sensor.organisation.id}`}>
                  {sensor.organisation?.name}
                </Link>
              ) : (
                <Typography>{'No Organisation'}</Typography>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Gateway">
              {sensor.gateway ? (
                <Link to={`/gateways/${sensor.gateway.id}`}>
                  <Typography>
                    {sensor.gateway.name.actual || sensor.gateway.id}
                  </Typography>
                </Link>
              ) : (
                <Typography>{'No Gateway'}</Typography>
              )}
            </Descriptions.Item>
            {sensor.smartSensor && sensor.smartSensor.actual != null && (
              <Descriptions.Item label="Smart Sensor">
                <Link to={`/smartSensors/${sensor.smartSensor.actual.id}`}>
                  {sensor.smartSensor.actual.id}
                </Link>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Pond">
              {sensor.pond?.inSync == false ? (
                <Alert
                  message="Not in sync!"
                  description={
                    <Descriptions layout="horizontal">
                      <Descriptions.Item label="Requested">
                        {sensor.pond?.requested ? (
                          <Link to={`/ponds/${sensor.pond?.requested?.id}`}>
                            {sensor.pond?.requested?.name ||
                              sensor.pond?.requested.id}
                          </Link>
                        ) : (
                          <Typography>{'No Pond'}</Typography>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Actual">
                        {sensor.pond?.actual ? (
                          <Link to={`/ponds/${sensor.pond?.actual?.id}`}>
                            {sensor.pond?.actual?.name ||
                              sensor.pond?.actual.id}
                          </Link>
                        ) : (
                          <Typography>{'No Pond'}</Typography>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                  type="warning"
                />
              ) : sensor.pond?.actual ? (
                <Link to={`/ponds/${sensor.pond?.actual?.id}`}>
                  {sensor.pond?.actual?.name || sensor.pond?.actual.id}
                </Link>
              ) : (
                <Typography>{'No Pond'}</Typography>
              )}

              {/* TODO: add form to change pondId of sensor  */}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderRemoteCalibrationCard = () => {
    return <SensorCalibrationCard sensorId={sensorId} />;
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="start">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderFormCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderGeneralDataCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderRemoteCalibrationCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderHierarchyInformationCard()}
          </Col>

          {sensorsWithCalibrationData.find((type) => type === sensor?.type) && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Card title="Calibration Logs">
                <SensorCalibrationLogs sensorId={sensorId} />
              </Card>
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card title="Sensor data" loading={loading}>
              {sensor && (
                <SensorAggregations
                  sensorId={sensorId}
                  sensorType={sensor.type}
                  queryParams={queryParams}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  sensor: state.sensing.detail.sensor,
  loading: state.sensing.detail.loading,
  error: state.sensing.detail.error,
  updating: state.sensing.detail.updating,
  updateError: state.sensing.detail.updateError,
  updateSuccess: state.sensing.detail.updateSuccess,
  gatewayState: state.gateways.detail,
  sensorCapOptionsState: state.options.sensorCapOptions,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSensorConnect: getSensor,
      getGatewayConnect: getGateway,
      updateSensorConnect: updateSensor,
      getSensorCapOptionsConnect: getSensorCapOptions,
    },
    dispatch,
  );
};

export const SensorDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorDetail);
