import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { INT_MAX } from '../../constants';
import { RootState } from '../../duck';
import { createAerator, getAerators } from '../../duck/modules/aerators';
import { getOrganisations } from '../../duck/modules/organisations';
import { getPonds } from '../../duck/modules/ponds';
import { CreateAeratorCmd } from '../../model/cmds';
import { AeratorModel } from '../../model/domain';
import {
  AeratorGroup,
  RelayboardId,
  RelayConnectionType,
  SmartRelayboardId,
} from '../../model/enums';
import { usePrevious } from '../_util/hook';
import { RelayboardIdOption } from '../../model/util';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId?: string;
    pondId?: string;
    gatewayId?: string;
    onCreated?: (aeratorModel: AeratorModel) => void;
    onError?: (error: Error) => void;
    relayboardOptions?: RelayboardIdOption[];
  };

const UnconnectedCreateAertorForm: React.FC<Props> = ({
  createAeratorConnect,
  getOrganisationsConnect,
  getPondsConnect,
  onCreated,
  onError,
  getAeratorsConnect,
  loading,
  error,
  success,
  organisations,
  ponds,
  organisationId: oId,
  pondId: pId,
  gatewayId: gId,
  relayboardOptions,
}) => {
  const [organisationId, setOrganisationId] = useState(oId);
  const [pondId, setPondId] = useState(pId);
  const [form] = Form.useForm();
  const prev = usePrevious({ success, error });
  const [formValues, setFormValues] = useState(form.getFieldsValue);

  React.useEffect(() => {
    if (!oId) {
      getOrganisationsConnect({ limit: INT_MAX, offset: 0 });
    }
  }, [organisationId]);

  React.useEffect(() => {
    if (!pId && organisationId) {
      getPondsConnect({
        limit: INT_MAX,
        offset: 0,
        organisationIds: [organisationId],
      });
    }
  }, [pondId, organisationId]);

  React.useEffect(() => {
    if (prev?.success === null && success) {
      notification.success({
        message: 'Aerator created',
      });
    }
    if (success && onCreated) {
      onCreated(success);
    }
    form.resetFields();
    setFormValues(form.getFieldsValue);
  }, [success]);

  React.useEffect(() => {
    if (prev?.error === null && error) {
      notification.error({
        message: 'Aerator creation error',
        description: error?.message,
      });
      if (error && onError) {
        onError(error);
      }
    }
  }, [error]);

  React.useEffect(() => {
    getAeratorsConnect({
      limit: INT_MAX,
      offset: 0,
    });
  }, []);

  const onFinish = (values: Store) => {
    if (organisationId) {
      const {
        group,
        connectionType,
        relayboardId,
        smartRelayId,
        smartRelayboardId,
        name,
      } = values;

      const createCmd: CreateAeratorCmd = {
        name,
        organisationId,
        pondId,
        group,
        relay: {
          connectionType,
        },
      };

      if (createCmd.relay?.connectionType === RelayConnectionType.RELAY_BOARD) {
        createCmd.relay.relayboardId = relayboardId;
      }
      if (createCmd.relay?.connectionType === RelayConnectionType.SMART_RELAY) {
        createCmd.relay.smartRelayId = smartRelayId;
        createCmd.relay.smartRelayboardId = smartRelayboardId;
      }

      createAeratorConnect(createCmd, gId, pondId);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={() => setFormValues(form.getFieldsValue())}
    >
      {!oId && (
        <Form.Item
          label="Organisation"
          name="organisationId"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select corresponding organisation"
            allowClear
            onChange={(value: string) => {
              setOrganisationId(value);
              form.setFieldsValue({ farmId: undefined });
            }}
          >
            {organisations.map((organisation) => (
              <Option key={organisation.id} value={organisation.id}>
                {organisation.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {!pId && organisationId && (
        <Form.Item label="Pond" name="pondId" rules={[{ required: true }]}>
          <Select
            placeholder="Select corresponding pond"
            allowClear
            onChange={(value: string) => {
              setPondId(value);
            }}
          >
            {ponds.map((pond) => (
              <Option key={pond.id} value={pond.id}>
                {pond.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {organisationId && pondId && (
        <Form.Item label="Connection" name="connectionType">
          <Select placeholder="Select connection" allowClear>
            <Option value={RelayConnectionType.RELAY_BOARD}>
              {'Relayboard'}
            </Option>
            <Option value={RelayConnectionType.SMART_RELAY}>
              {'Smartrelay'}
            </Option>
          </Select>
        </Form.Item>
      )}

      {organisationId &&
        pondId &&
        formValues.connectionType === RelayConnectionType.RELAY_BOARD && (
          <Form.Item label="Relay" name="relayboardId">
            <Select placeholder="Select relay from relayboard" allowClear>
              {relayboardOptions
                ? relayboardOptions.map((o) => (
                  <Option key={o.value} value={o.value}>
                    {o.label}
                  </Option>
                ))
                : [
                  <Option value={RelayboardId.RELAY_1}>{'Relay 1'}</Option>,
                  <Option value={RelayboardId.RELAY_2}>{'Relay 2'}</Option>,
                  <Option value={RelayboardId.RELAY_3}>{'Relay 3'}</Option>,
                  <Option value={RelayboardId.RELAY_4}>{'Relay 4'}</Option>,
                  <Option value={RelayboardId.RELAY_5}>{'Relay 5'}</Option>,
                  <Option value={RelayboardId.RELAY_6}>{'Relay 6'}</Option>,
                  <Option value={RelayboardId.RELAY_7}>{'Relay 7'}</Option>,
                  <Option value={RelayboardId.RELAY_8}>{'Relay 8'}</Option>,
                  <Option value={RelayboardId.RELAY_9}>{'Relay 9'}</Option>,
                  <Option value={RelayboardId.RELAY_10}>{'Relay 10'}</Option>,
                  <Option value={RelayboardId.RELAY_11}>{'Relay 11'}</Option>,
                  <Option value={RelayboardId.RELAY_12}>{'Relay 12'}</Option>,
                  <Option value={RelayboardId.RELAY_13}>{'Relay 13'}</Option>,
                  <Option value={RelayboardId.RELAY_14}>{'Relay 14'}</Option>,
                  <Option value={RelayboardId.RELAY_15}>{'Relay 15'}</Option>,
                  <Option value={RelayboardId.RELAY_16}>{'Relay 16'}</Option>,
                ]}
            </Select>
          </Form.Item>
        )}

      {organisationId &&
        pondId &&
        formValues.connectionType === RelayConnectionType.SMART_RELAY && (
          <Form.Item label="Smartrelay" name="smartRelayId">
            <Input type="text" placeholder="Id of the device" />
          </Form.Item>
        )}

      {organisationId &&
        pondId &&
        formValues.connectionType === RelayConnectionType.SMART_RELAY &&
        formValues.smartRelayId && (
          <Form.Item label="Relay" name="smartRelayboardId">
            <Select placeholder="Select relay from smartRelayBox" allowClear>
              <Option value={SmartRelayboardId.RELAY_1}>{'Relay 1'}</Option>
              <Option value={SmartRelayboardId.RELAY_2}>{'Relay 2'}</Option>
            </Select>
          </Form.Item>
        )}

      {organisationId &&
        pondId &&
        formValues.connectionType &&
        (formValues.relayboardId ||
          (formValues.smartRelayId && formValues.smartRelayboardId)) && (
          <Form.Item label="Aerator Group" name="group">
            <Select placeholder="Select group" allowClear>
              <Option value={AeratorGroup.GROUP_1}>{'Group 1'}</Option>
              <Option value={AeratorGroup.GROUP_2}>{'Group 2'}</Option>
              <Option value={AeratorGroup.GROUP_3}>{'Group 3'}</Option>
              <Option value={AeratorGroup.GROUP_4}>{'Group 4'}</Option>
              <Option value={AeratorGroup.GROUP_5}>{'Group 5'}</Option>
              <Option value={AeratorGroup.GROUP_6}>{'Group 6'}</Option>
              <Option value={AeratorGroup.GROUP_7}>{'Group 7'}</Option>
              <Option value={AeratorGroup.GROUP_8}>{'Group 8'}</Option>
              <Option value={AeratorGroup.GROUP_9}>{'Group 9'}</Option>
              <Option value={AeratorGroup.GROUP_10}>{'Group 10'}</Option>
              <Option value={AeratorGroup.GROUP_11}>{'Group 11'}</Option>
              <Option value={AeratorGroup.GROUP_12}>{'Group 12'}</Option>
            </Select>
          </Form.Item>
        )}

      {organisationId &&
        pondId &&
        formValues.connectionType &&
        (formValues.relayboardId ||
          (formValues.smartRelayId && formValues.smartRelayboardId)) &&
        formValues.group && (
          <Form.Item label="Aerator Name" name="name">
            <Input type="text" placeholder="Name to identify this aerator" />
          </Form.Item>
        )}

      {organisationId &&
        pondId &&
        formValues.connectionType &&
        (formValues.relayboardId ||
          (formValues.smartRelayId && formValues.smartRelayboardId)) &&
        formValues.group && (
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Create Aerator
              </Button>
            </Space>
          </Form.Item>
        )}
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.aerators.create.loading,
  error: state.aerators.create.error,
  success: state.aerators.create.aerator,
  organisations: state.organisations.list.organisations,
  ponds: state.ponds.list.ponds,
  aerators: state.aerators.list.aerators,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createAeratorConnect: createAerator,
      getOrganisationsConnect: getOrganisations,
      getPondsConnect: getPonds,
      getAeratorsConnect: getAerators,
    },
    dispatch,
  );
};

export const CreateAeratorForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateAertorForm);
