import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Typography,
  Button,
  Descriptions,
  Modal,
  Form,
  Empty,
  DatePicker,
  Switch
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  getPond,
  updatePond,
  updatePondThresholds,
} from '../../duck/modules/ponds';
import { PondsSensorDataTable } from '../pond/pondsSensorDataTable';
import { UpdatePondCmd, UpdateAerationProfileCmd } from '../../model/cmds';
import { PondDataForm } from '../pond/pondDataForm';
import { PondThresholdsTabs } from '../pond/pondThresholdsTabs';
import {
  getAerationProfile,
  updateAerationProfile,
} from '../../duck/modules/aerationProfiles';
import { AerationProfileForm } from '../pond/aerationProfileForm';
import { NotificationsTable } from '../notification/notificationsTable';
import { getDayOfCultivation, getShortNameToAeration } from '../../util';
import { createCycle, updateCycle } from '../../duck/modules/cycles';
import { CycleState } from '../../model/enums';
import moment from 'moment';
import { PondSensorDataTable } from '../pond/pondSensorDataTable';
import { PondSensorsList } from '../pond/pondSensorsList';
import { PondAeratorsList } from '../pond/pondAeratorsList';
import { PondGatewayHierarchy } from '../pond/pondGatewayHierarchy';
import { PondPumpsList } from '../pond/pondPumpsList';
import { activatePond, deactivatePond } from '../../duck/modules/activation';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ pondId: string }>;

const UnconnectedPondDetail: React.FC<Props> = ({
  getPondConnect,
  pond,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  updatePondConnect,
  updatingThresholds,
  updateThresholdsSuccess,
  updateThresholdsError,
  updatePondThresholdsConnect,
  getAerationProfileConnect,
  updateAerationProfileConnect,
  createCycleConnect,
  updateCycleConnect,
  aerationProfileState,
  activationState,
  activatePondConnect,
  deactivatePondConnect,
}) => {
  const { pondId } = match.params;
  const [triggerReload, setTriggerReload] = useState(false);
  const [startCycleModalVisible, setStartCycleModalVisible] = React.useState(
    false,
  );

  React.useEffect(() => {
    getPondConnect(pondId);
  }, []);

  React.useEffect(() => {
    getAerationProfileConnect(pondId);
  }, []);

  if (error) {
    return (
      <Result status="error" title="Can't load pond" subTitle={error.message} />
    );
  }

  const renderSensorDataCard = () => {
    return (
      <Card title={'Sensor data'} loading={loading}>
        {pond && <PondSensorDataTable pond={pond} />}
      </Card>
    );
  };

  const renderGeneralDataCard = () => {
    return (
      <Card title={'General Data'} loading={loading}>
        {pond && (
          <PondDataForm
            updating={updating}
            updateSuccess={updateSuccess}
            updateError={updateError}
            onUpdate={(cmd: UpdatePondCmd) => updatePondConnect(pondId, cmd)}
            pond={pond}
          />
        )}
      </Card>
    );
  };

  const renderCycleCard = () => {
    return (
      <Card title={'Cycle'} loading={loading}>
        {pond && (!pond.cycle || pond.cycle.state === CycleState.DONE) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'No active cycle!'}
          >
            {renderStartCycleModal()}
            <Button
              type="primary"
              onClick={() => setStartCycleModalVisible(true)}
            >
              Start cycle
            </Button>
          </Empty>
        )}
        {pond && pond.cycle && pond.cycle.state !== CycleState.DONE && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Active Cycle">
              <Row justify="space-between">
                <Col>
                  <Link to={`/cycles/${pond.cycle.id}`}>
                    {`DoC: ${getDayOfCultivation(pond.cycle.startTimestamp)}`}
                  </Link>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={() => {
                      if (pond.cycle) {
                        updateCycleConnect(
                          pond.cycle?.id,
                          { state: CycleState.DONE },
                          pondId,
                        );
                      }
                    }}
                  >
                    End Cycle
                  </Button>
                </Col>
              </Row>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderAutomationCard = () => {
    return (
      <Card
        title={
          <Row justify="space-between">
            <Typography>Automation</Typography>
            <Typography>
              {pond
                ? `${getShortNameToAeration(pond.aeration.automation)}`
                : ''}
            </Typography>
          </Row>
        }
        loading={aerationProfileState.loading || loading}
      >
        <Col>
          {aerationProfileState.aerationProfile && pond && (
            <AerationProfileForm
              updating={aerationProfileState.updating}
              updateSuccess={aerationProfileState.updateSuccess}
              updateError={aerationProfileState.updateError}
              onUpdate={(cmd: UpdateAerationProfileCmd) =>
                updateAerationProfileConnect(pondId, cmd)
              }
              aerationProfile={aerationProfileState.aerationProfile}
            />
          )}
        </Col>
      </Card>
    );
  };

  const renderNotificationSettingsCard = () => {
    return (
      <Card
        style={{ width: '100%' }}
        title={'Notification settings'}
        loading={loading}
      >
        {pond && (
          <PondThresholdsTabs
            updating={updatingThresholds}
            updateSuccess={updateThresholdsSuccess}
            updateError={updateThresholdsError}
            onUpdate={(cmd: UpdatePondCmd) =>
              updatePondThresholdsConnect(pondId, cmd)
            }
            pond={pond}
          />
        )}
      </Card>
    );
  };

  const renderHierarchyInformationCard = () => {
    return (
      <Card style={{ width: '100%' }} title={'Hierarchy'} loading={loading}>
        {pond && <PondGatewayHierarchy pond={pond} />}
      </Card>
    );
  };

  const renderSensorsCard = () => {
    if (loading == false && pond && pond.gateway == null) {
      return <div />;
    }
    return (
      <Card style={{ width: '100%' }} loading={loading}>
        {pond && <PondSensorsList pond={pond} />}
      </Card>
    );
  };

  const renderAeratorsCard = () => {
    if (loading == false && pond && pond.gateway == null) {
      return <div />;
    }
    return (
      <Card style={{ width: '100%' }} loading={loading}>
        {pond && <PondAeratorsList pond={pond} />}
      </Card>
    );
  };

  const renderNotificationsCard = () => {
    return (
      <Card title={'Notifications'} loading={loading}>
        <NotificationsTable
          pondId={pondId}
          reload={triggerReload}
          onReloaded={() => setTriggerReload(false)}
          pageSize={5}
          hidePond
        />
      </Card>
    );
  };

  const renderPumpsCard = () => {
    if (loading == false && pond && pond.gateway == null) {
      return <div />;
    }
    return (
      <Card style={{ width: '100%' }} loading={loading}>
        {pond && <PondPumpsList pond={pond} />}
      </Card>
    );
  };

  const renderStartCycleModal = () => {
    return (
      <Modal
        visible={startCycleModalVisible}
        onOk={() => setStartCycleModalVisible(false)}
        onCancel={() => setStartCycleModalVisible(false)}
        footer={null}
        title={'Create a cycle'}
      >
        <Form
          initialValues={{
            start: moment(),
          }}
          onFinish={(values) => {
            const { start } = values;
            createCycleConnect(pondId, {
              startTimestamp: moment(start).format('YYYY-MM-DD') + 'T00:00:00Z',
            });
            setStartCycleModalVisible(false);
          }}
        >
          <Form.Item
            label="Start date"
            name="start"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD / MM / YYYY'}
              placeholder="Day of cultivation"
              disabledDate={(d) => d.isAfter(moment())}
            />
          </Form.Item>

          <Form.Item>
            <Row justify="space-between">
              <Col>
                <Button type="primary" htmlType="submit" loading={updating}>
                  Start Cycle
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const activationWidget = () => {
    return (
      <Switch
        checked={pond?.isActive}
        checkedChildren={"Active"}
        unCheckedChildren={"Inactive"}
        onClick={() => pond?.isActive ? deactivatePondConnect(pondId) : activatePondConnect(pondId)}
        loading={activationState.loading}
      />
    );
  }

  const enabledWidget = () => {
    return (
      <Switch
        checked={pond?.enabled}
        checkedChildren={"Enabled"}
        unCheckedChildren={"Disabled"}
        onClick={() => pond?.enabled ? updatePondConnect(pondId, { enabled: false }) : updatePondConnect(pondId, { enabled: true })}
        loading={updating}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="center">
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{enabledWidget()}</Col>
              <Col>{activationWidget()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>{renderGeneralDataCard()}</Col>
              <Col xs={24}>{renderCycleCard()}</Col>
              {/* <Col xs={24}>{renderNotificationsCard()}</Col> */}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>{renderHierarchyInformationCard()}</Col>
              <Col xs={24}>{renderNotificationSettingsCard()}</Col>
              <Col xs={24}>{renderAutomationCard()}</Col>
            </Row>
          </Col>
          <Col span={24}>{renderSensorDataCard()}</Col>
          <Col span={24}>{renderSensorsCard()}</Col>
          <Col span={24}>{renderAeratorsCard()}</Col>
          <Col span={24}>{renderPumpsCard()}</Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  pond: state.ponds.detail.pond,
  loading: state.ponds.detail.loading,
  error: state.ponds.detail.error,
  updating: state.ponds.detail.updating,
  updateError: state.ponds.detail.updateError,
  updateSuccess: state.ponds.detail.updateSuccess,
  updatingThresholds: state.ponds.detail.thresholds.updating,
  updateThresholdsSuccess: state.ponds.detail.thresholds.updateSuccess,
  updateThresholdsError: state.ponds.detail.thresholds.updateError,
  aerationProfileState: state.aerationProfiles.detail,
  activationState: state.activation.pond,

});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPondConnect: getPond,
      updatePondConnect: updatePond,
      updatePondThresholdsConnect: updatePondThresholds,
      createCycleConnect: createCycle,
      getAerationProfileConnect: getAerationProfile,
      updateAerationProfileConnect: updateAerationProfile,
      updateCycleConnect: updateCycle,
      activatePondConnect: activatePond,
      deactivatePondConnect: deactivatePond,

    },
    dispatch,
  );
};

export const PondDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondDetail);
