
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { messageApi } from '../../api/messageApi';
import {
  CREATE_MESSAGE,
  CREATE_MESSAGE_FAILED,
  CREATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILED,
  DELETE_MESSAGE_SUCCESS,
  FETCH_MESSAGE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGE_FAILED,
  FETCH_MESSAGE_SUCCESS,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_FAILED,
  UPDATE_MESSAGE_SUCCESS,
} from '../../constants';
import {
  CreateMessageCmd,
  UpdateMessageCmd,
  UpdatePopUpMessageCmd,
} from '../../model/cmds';
import { ListMessagesQuery, ListMessagesQueryModel } from '../../model/querys';
import { MessageState } from '../../model/state';
import {
  messageCreate,
  messageCreated,
  messageCreateFailed,
  messageDelete,
  messageDeleted,
  messageDeleteFailed,
  messageFetch,
  messageFetched,
  messageFetchFailed,
  MessagesAction,
  messagesFetch,
  messagesFetched,
  messageUpdate,
  messageUpdated,
  messageUpdateFailed,
} from '../actions/messages';

export function createMessage(cmd: CreateMessageCmd) {
  return async (dispatch: Dispatch<MessagesAction, {}, any>) => {
    try {
      dispatch(messageCreate());
      const message = await messageApi.createMessage(cmd);
      dispatch(messageCreated(message));
    } catch (e) {
      dispatch(messageCreateFailed(e as Error));
    }
  };
}

export function getMessage(id: string) {
  return async (dispatch: Dispatch<MessagesAction, {}, any>) => {
    try {
      dispatch(messageFetch());
      const message = await messageApi.getPopUpMessage(id);
      dispatch(messageFetched(message));
    } catch (e) {
      dispatch(messageFetchFailed(e as Error));
    }
  };
}

export function getMessages(query: ListMessagesQuery) {
  return async (dispatch: Dispatch<MessagesAction, {}, any>) => {
    try {
      dispatch(messagesFetch(query));
      const messages: ListMessagesQueryModel = await messageApi.listPopUpMessages(
        query,
      );
      dispatch(messagesFetched(messages, messages.messages));
    } catch (e) {
      dispatch(messageFetchFailed(e as Error));
    }
  };
}

export function updateMessage(id: string, cmd: UpdateMessageCmd) {
  return async (dispatch: Dispatch<MessagesAction, {}, any>) => {
    try {
      dispatch(messageUpdate());
      const message = await messageApi.updateMessage(id, cmd);
      dispatch(messageUpdated(message));
    } catch (e) {
      dispatch(messageUpdateFailed(e as Error));
    }
  };
}

export function deleteMessage(id: string) {
  return async (dispatch: Dispatch<MessagesAction, {}, any>) => {
    try {
      dispatch(messageDelete());
      const message = await messageApi.deleteMessage(id);
      dispatch(messageDeleted(message));
    } catch (e) {
      dispatch(messageDeleteFailed(e as Error));
    }
  };
}
export default function messageReducer(
  state: MessageState = {
    create: {
      loading: false,
      error: null,
      message: null,
    },
    list: {
      loading: false,
      error: null,
      messages: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      message: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
      deleting: false,
      deleteSuccess: null,
      deleteError: null,
    },
  },
  action: MessagesAction,
): MessageState {
  switch (action.type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          message: null,
        },
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          message: action.payload,
        },
      };
    case CREATE_MESSAGE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          message: null,
        },
      };
    case FETCH_MESSAGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          message: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          message: action.payload,
        },
      };
    case FETCH_MESSAGE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          error: action.payload,
          message: null,
        },
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_MESSAGE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: true,
          deleteSuccess: null,
          deleteError: null,
        },
      };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: true,
          deleteError: null,
        },
      };
    case DELETE_MESSAGE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: null,
          deleteError: action.payload,
        },
      };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: false,
          deleteError: null,
        },
      };
    case FETCH_MESSAGES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          messages: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          messages: action.payload.messages,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };
    case FETCH_MESSAGE_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          messages: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}
