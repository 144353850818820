import {
  Card,
  Col,
  Layout,
  Result,
  Row,
  Typography,
  Descriptions,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { RootState } from '../../duck';
import { UpdateAeratorCmd } from '../../model/cmds';
import { getAerator, updateAerator } from '../../duck/modules/aerators';
import { AeratorEntityForm } from '../aerator/aeratorEntityForm';
import { RelayConnectionType } from '../../model/enums';
import { getRelayboardIdOptions } from '../../util';
import { getGateway } from '../../duck/modules/gateways';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ aeratorId: string }>;

const UnconnectedAertorDetail: React.FC<Props> = ({
  aerator,
  match,
  loading,
  error,
  getAeratorConnect,
  getGatewayConnect,
  updateAeratorConnect,
  updating,
  updateError,
  updateSuccess,
  gatewayState,
}) => {
  const { aeratorId } = match.params;

  React.useEffect(() => {
    getAeratorConnect(aeratorId);
  }, []);

  React.useEffect(() => {
    if (aerator && aerator.gateway.id) {
      getGatewayConnect(aerator.gateway.id);
    }
  }, [aerator]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load aerator data"
        subTitle={error.message}
      />
    );
  }

  const renderFormCard = () => {
    return (
      <Card title={'General Data'} loading={loading}>
        {aerator && gatewayState.gateway && !gatewayState.loading && (
          <AeratorEntityForm
            aerator={aerator}
            updating={updating}
            updateSuccess={updateSuccess}
            updateError={updateError}
            onUpdate={(cmd: UpdateAeratorCmd) => {
              updateAeratorConnect(aeratorId, cmd);
            }}
            relayboardIdOptions={getRelayboardIdOptions(gatewayState.gateway)}
          />
        )}
      </Card>
    );
  };

  const renderGeneralDataCard = () => {
    return (
      <Card title={'Operation Data'} loading={loading}>
        {aerator && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Connection Type">
              <Typography>{aerator.relay.actual.connectionType}</Typography>
            </Descriptions.Item>
            <Descriptions.Item label="Relay State">
              <Typography>{aerator.relay.actual.state}</Typography>
            </Descriptions.Item>
            {aerator.relay.actual.connectionType ===
              RelayConnectionType.SMART_RELAY && (
                <Descriptions.Item label="Smart Relay (DeviceId)">
                  <Typography>{aerator.relay.actual.smartRelayId}</Typography>
                </Descriptions.Item>
              )}
            {aerator.relay.actual.connectionType ===
              RelayConnectionType.SMART_RELAY && (
                <Descriptions.Item label="Smart Relay (Relay)">
                  <Typography>
                    {aerator.relay.actual.smartRelayboardId}
                  </Typography>
                </Descriptions.Item>
              )}
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderHierarchyInformationCard = () => {
    return (
      <Card title={'Hierarchy'} loading={loading}>
        {aerator && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Gateway">
              {aerator.gateway ? (
                <Link to={`/gateways/${aerator.gateway.id}`}>
                  {aerator.gateway.name || aerator.gateway.id}
                </Link>
              ) : (
                <Typography>{'No Gateway'}</Typography>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Pond">
              {aerator.pond ? (
                <Link to={`/ponds/${aerator.pond.actual.id}`}>
                  {aerator.pond.actual.name || aerator.pond.actual.id}
                </Link>
              ) : (
                <Typography>{'No Pond'}</Typography>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="start">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderFormCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[20, 20]} justify="start">
              <Col span={24}>{renderHierarchyInformationCard()}</Col>
              <Col span={24}>{renderGeneralDataCard()}</Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  aerator: state.aerators.detail.aerator,
  loading: state.aerators.detail.loading,
  error: state.aerators.detail.error,
  updating: state.aerators.detail.updating,
  updateError: state.aerators.detail.updateError,
  updateSuccess: state.aerators.detail.updateSuccess,
  gatewayState: state.gateways.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAeratorConnect: getAerator,
      updateAeratorConnect: updateAerator,
      getGatewayConnect: getGateway,
    },
    dispatch,
  );
};

export const AeratorDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAertorDetail);
