import {
  TRIGGER_CALIBRATION,
  TRIGGER_CALIBRATION_SUCCESS,
  TRIGGER_CALIBRATION_FAILED,
  CALIBRATION_LOG_EVENT_RECEIVED,
  CALIBRATION_SUCCESS,
  CALIBRATION_FAILED,
  CHECK_CALIBRATION,
  CHECK_CALIBRATION_SUCCESS,
  CHECK_CALIBRATION_FAILED,
} from '../../constants';
import { CalibrateSensorCmd } from '../../model/cmds';
import { SensorCalibrationLogModel, SensorModel } from '../../model/domain';

export interface TriggerCalibrationAction {
  type: TRIGGER_CALIBRATION;
  payload: {
    sensorId: string;
    cmd: CalibrateSensorCmd;
  };
}

export interface TriggerCalibrationSuccessAction {
  type: TRIGGER_CALIBRATION_SUCCESS;
  payload: SensorCalibrationLogModel;
}

export interface TriggerCalibrationFailedAction {
  type: TRIGGER_CALIBRATION_FAILED;
  payload: Error;
}

export interface CalibrationLogEventReceived {
  type: CALIBRATION_LOG_EVENT_RECEIVED;
  payload: any;
}

export interface CalibrationSuccessAction {
  type: CALIBRATION_SUCCESS;
  payload: any;
}

export interface CalibrationFailedAction {
  type: CALIBRATION_FAILED;
  payload: Error;
}

export interface CheckingCalibrationAction {
  type: CHECK_CALIBRATION;
  payload: string;
}

export interface CheckingCalibrationSuccessAction {
  type: CHECK_CALIBRATION_SUCCESS;
  payload: { result: boolean; sensor: SensorModel };
}

export interface CheckingCalibrationFailedAction {
  type: CHECK_CALIBRATION_FAILED;
  payload: Error;
}

export type CheckingCalibration =
  | CheckingCalibrationAction
  | CheckingCalibrationSuccessAction
  | CheckingCalibrationFailedAction;

export type CalibrationAction =
  | TriggerCalibrationAction
  | TriggerCalibrationSuccessAction
  | TriggerCalibrationFailedAction
  | CalibrationLogEventReceived
  | CalibrationSuccessAction
  | CalibrationFailedAction
  | CheckingCalibration;

export function checkCalibration(sensorId: string): CheckingCalibration {
  return {
    type: CHECK_CALIBRATION,
    payload: sensorId,
  };
}

export function checkCalibrationSuccess(
  result: boolean,
  sensor: SensorModel,
): CheckingCalibrationSuccessAction {
  return {
    type: CHECK_CALIBRATION_SUCCESS,
    payload: {
      sensor,
      result,
    },
  };
}

export function checkCalibrationFailed(
  error: Error,
): CheckingCalibrationFailedAction {
  return {
    type: CHECK_CALIBRATION_FAILED,
    payload: error,
  };
}

export function triggerCalibration(
  sensorId: string,
  cmd: CalibrateSensorCmd,
): TriggerCalibrationAction {
  return {
    type: TRIGGER_CALIBRATION,
    payload: {
      sensorId,
      cmd,
    },
  };
}

export function triggerCalibrationSuccess(
  log: SensorCalibrationLogModel,
): TriggerCalibrationSuccessAction {
  return {
    type: TRIGGER_CALIBRATION_SUCCESS,
    payload: log,
  };
}

export function triggerCalibrationFailed(
  error: Error,
): TriggerCalibrationFailedAction {
  return {
    type: TRIGGER_CALIBRATION_FAILED,
    payload: error,
  };
}

export function onCalibrationLogEvent(event: any): CalibrationLogEventReceived {
  return {
    type: CALIBRATION_LOG_EVENT_RECEIVED,
    payload: event,
  };
}

export function calibrationSuccess(payload: any): CalibrationSuccessAction {
  return {
    type: CALIBRATION_SUCCESS,
    payload,
  };
}

export function calibrationFailed(payload: any): CalibrationFailedAction {
  return {
    type: CALIBRATION_FAILED,
    payload,
  };
}
