import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  ListSensorsQueryModel,
  ListSensorsQuery,
  ListSensorDataAggregationQuery,
  ListSensorDataAggregationQueryModel,
} from '../model/querys';
import {
  CreateSensorCmd,
  AddSensorToOrganisationCmd,
  UpdateSensorCmd,
  CalibrateSensorCmd,
} from '../model/cmds';
import { SensorCalibrationLogModel, SensorModel } from '../model/domain';

export class SensingApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createSensor = this.createSensor.bind(this);
    this.getSensor = this.getSensor.bind(this);
    this.listSensors = this.listSensors.bind(this);
    this.listOrganisationSensors = this.listOrganisationSensors.bind(this);
    this.updateSensor = this.updateSensor.bind(this);
    this.addSensorToOrganisation = this.addSensorToOrganisation.bind(this);
    this.removeSensorFromOrganisation = this.removeSensorFromOrganisation.bind(
      this,
    );
    this.getSensorDataAggregations = this.getSensorDataAggregations.bind(this);
  }

  public createSensor(cmd: CreateSensorCmd): Promise<SensorModel> {
    console.warn(JSON.stringify(cmd));
    return this.post<SensorModel, CreateSensorCmd, AxiosResponse<SensorModel>>(
      '/v1/sensors',
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getSensor(sensorId: string): Promise<SensorModel> {
    return this.get<SensorModel, AxiosResponse<SensorModel>>(
      `/v1/sensors/${sensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listSensors(query?: ListSensorsQuery): Promise<ListSensorsQueryModel> {
    const config = getConfig();
    if (query) {
      config.params = query;
    }
    return this.get<
      ListSensorsQueryModel,
      AxiosResponse<ListSensorsQueryModel>
    >(`/v1/sensors`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listOrganisationSensors(
    organisationId: string,
    query?: ListSensorsQuery,
  ): Promise<ListSensorsQueryModel> {
    const config = getConfig();
    if (query) {
      config.params = query;
    }
    return this.get<
      ListSensorsQueryModel,
      AxiosResponse<ListSensorsQueryModel>
    >(`/v1/organisations/${organisationId}/sensors`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateSensor(
    sensorId: string,
    cmd: UpdateSensorCmd,
  ): Promise<SensorModel> {
    return this.patch<SensorModel, UpdateSensorCmd, AxiosResponse<SensorModel>>(
      `/v1/sensors/${sensorId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public calibrateSensor(
    sensorId: string,
    cmd: CalibrateSensorCmd,
  ): Promise<SensorCalibrationLogModel> {
    return this.post<
      SensorCalibrationLogModel,
      CalibrateSensorCmd,
      AxiosResponse<SensorCalibrationLogModel>
    >(`/v1/sensors/${sensorId}/_calibrate`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addSensorToOrganisation(
    organisationId: string,
    cmd: AddSensorToOrganisationCmd,
  ): Promise<SensorModel> {
    return this.post<
      SensorModel,
      AddSensorToOrganisationCmd,
      AxiosResponse<SensorModel>
    >(`/v1/organisations/${organisationId}/sensors`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeSensorFromOrganisation(
    organisationId: string,
    sensorId: string,
  ): Promise<void> {
    return this.delete<void, AxiosResponse<void>>(
      `/v1/organisations/${organisationId}/sensors/${sensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getSensorDataAggregations(
    query: ListSensorDataAggregationQuery,
  ): Promise<ListSensorDataAggregationQueryModel> {
    const config = getConfig();
    if (query) {
      config.params = query;
    }
    return this.get<
      ListSensorDataAggregationQueryModel,
      AxiosResponse<ListSensorDataAggregationQueryModel>
    >(`/v1/sensors/${query.sensorId}/aggregations`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const sensingApi = new SensingApi();
