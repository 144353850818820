import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Tooltip,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { AeratorModel } from '../../model/domain';
import {
  UpdateAeratorCmd,
  UpdateRelayCmd,
} from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { usePrevious } from '../_util/hook';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';
import {
  AeratorType,
  AeratorGroup,
  RelayConnectionType,
} from '../../model/enums';
import { getNameToAeratorType, getNameToRelayboardId } from '../../util';
import { randomBytes } from 'crypto';
import { RelayboardIdOption } from '../../model/util';

const { Option } = Select;

type Props = {
  aerator: AeratorModel;
  onUpdate: (cmd: UpdateAeratorCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
  relayboardIdOptions: RelayboardIdOption[];
};

export const AeratorEntityForm: React.FC<Props> = ({
  aerator,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
  relayboardIdOptions,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });
  const [formValues, setFormValues] = React.useState(form.getFieldsValue);
  React.useEffect(() => {
    form.setFieldsValue({
      ...aerator,
      name: aerator.name
        ? aerator.name.inSync
          ? aerator.name.actual
          : aerator.name.requested
        : '',
      group: aerator.group.inSync
        ? aerator.group.actual
        : aerator.group.requested,
      relayboardId: aerator.relay.inSync
        ? aerator.relay.actual.relayboardId
        : aerator.relay.requested?.relayboardId,
      connectionType: aerator.relay.inSync
        ? aerator.relay.actual.connectionType
        : aerator.relay.requested?.connectionType,
      // smartReloadboardId: aerator.relay.inSync
      //   ? aerator.relay.actual.smartRelayboardId
      //   : aerator.relay.requested?.smartRelayboardId,
      // smartRelayId: aerator.relay.inSync
      //   ? aerator.relay.actual.smartRelayId
      //   : aerator.relay.requested?.smartRelayId,
      // state: aerator.relay.inSync
      //   ? aerator.relay.actual.state
      //   : aerator.relay.requested?.state,
    });
    setFormValues(form.getFieldsValue());
  }, [aerator, updateSuccess, updateError]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Aerator updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating aerator',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      name,
      group,
      type,
      connectionType,
      relayboardId,
      smartRelayboardId,
      smartRelayId,
    } = values;

    const cmd: UpdateAeratorCmd = {};

    const relayCmd: UpdateRelayCmd = {};

    if (name !== aerator.name?.actual) {
      cmd.name = name;
    }

    if (group !== aerator.group.actual) {
      cmd.group = group;
    }
    if (type !== aerator.type) {
      cmd.type = type;
    }

    if (connectionType != aerator.relay.actual.connectionType) {
      relayCmd.connectionType = connectionType;
    }

    if (connectionType === RelayConnectionType.RELAY_BOARD) {
      if (relayboardId != aerator.relay.actual.relayboardId) {
        relayCmd.relayboardId = relayboardId;
      }
    } else if (connectionType === RelayConnectionType.SMART_RELAY) {
      if (smartRelayboardId != aerator.relay.actual.smartRelayboardId) {
        relayCmd.smartRelayboardId = smartRelayboardId;
      }
      if (smartRelayId != aerator.relay.actual.smartRelayId) {
        relayCmd.smartRelayId = smartRelayId;
      }
    }

    if (JSON.stringify(relayCmd) != '{}') {
      cmd.relay = relayCmd;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && aerator) {
      cmd.name =
        aerator.name && (aerator.name.requested || aerator.name.actual);
      cmd.group =
        aerator.group && (aerator.group.requested || aerator.group.actual);
      cmd.type = aerator.type;
      cmd.relay = {
        relayboardId:
          aerator.relay.requested?.relayboardId ||
          aerator.relay.actual.relayboardId,
        smartRelayId:
          aerator.relay.requested?.smartRelayId ||
          aerator.relay.actual.smartRelayId,
        smartRelayboardId:
          aerator.relay.requested?.smartRelayboardId ||
          aerator.relay.actual.smartRelayboardId,
      };
    }

    console.log(cmd);

    onUpdate(cmd);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={() => setFormValues(form.getFieldsValue())}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: false }]}
      >
        <Input
          placeholder="Name of the aerator"
          suffix={
            aerator.name?.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      <Form.Item
        label="Connection Type"
        name="connectionType"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select connection type for the relay"
          suffixIcon={
            aerator.relay?.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        >
          <Option value={RelayConnectionType.RELAY_BOARD}>
            {RelayConnectionType.RELAY_BOARD}
          </Option>
          <Option value={RelayConnectionType.SMART_RELAY} disabled>
            {RelayConnectionType.SMART_RELAY}
          </Option>
        </Select>
      </Form.Item>

      {form.getFieldValue('connectionType') ===
        RelayConnectionType.RELAY_BOARD && (
          <Form.Item
            label="Relay"
            name="relayboardId"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select relay from relayboard"
              suffixIcon={
                aerator.relay?.inSync ? (
                  <Tooltip title={'Synchronized'}>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={`Syncing...`}>
                    <WarningOutlined style={{ color: functionalColor.warning }} />
                  </Tooltip>
                )
              }
            >
              <Option value={aerator.relay.actual.relayboardId}>
                {getNameToRelayboardId(aerator.relay.actual.relayboardId)}
              </Option>
              {relayboardIdOptions.flatMap((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

      {/* {
        form.getFieldValue('connectionType') === RelayConnectionType.SMART_RELAY &&
          <Form.Item
            label="Relay"
            name="smartRelayId"
            rules={[{ required: true }]}
          >
              <Input
                placeholder="Enter ID of the smart relay device"
                suffix={
                  aerator.relay.inSync ? (
                    <Tooltip title={"Synchronized"}>
                      <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Syncing...`}>
                      <WarningOutlined style={{ color: functionalColor.warning }} />
                    </Tooltip>
                  )
                }
              />
          </Form.Item>
         }

      {
        form.getFieldValue('connectionType') === RelayConnectionType.SMART_RELAY &&
          <Form.Item
            label="Relay"
            name="smartRelayboardId"
            rules={[{ required: true }]}
            help="Make sure that relay is not already in use!"
          >
            <Select
              placeholder="Select relay from smart relay device"
              suffixIcon={
                aerator.relay?.inSync ? (
                  <Tooltip title={"Synchronized"}>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={`Syncing...`}>
                    <WarningOutlined style={{ color: functionalColor.warning }} />
                  </Tooltip>
                )
              }
            >
              {
            // TODO: hide smartRelayboardId options that are already in use at gateway
              Object.values(SmartRelayboardId).map(smartRelayboardId => 
                <Option id={`smartRelayboardId-${smartRelayboardId.toString()}`} value={smartRelayboardId}>
                    {smartRelayboardId}
                </Option>
              ).values}
            </Select>
          </Form.Item>
         } */}

      <Form.Item label="Group" name="group" rules={[{ required: true }]}>
        <Select
          placeholder="Select group for the device"
          suffixIcon={
            aerator.group?.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        >
          {Object.values(AeratorGroup).flatMap((group) => (
            <Option
              key={`${group.toString()}-${randomBytes(30).toString()}`}
              value={group}
            >
              {group}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Type" name="type" rules={[{ required: true }]}>
        <Select placeholder="Select type">
          {Object.values(AeratorType).flatMap((type) => (
            <Option key={`aeratorType-${type.toString()}`} value={type}>
              {getNameToAeratorType(type)}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Aerator
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
