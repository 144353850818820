import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import {
  getOrganisations,
} from '../../duck/modules/organisations';
import { SensorModel } from '../../model/domain';
import { INT_MAX } from '../../constants';
import { createSensor } from '../../duck/modules/sensing';
import { SensorType } from '../../model/enums';
import { getSensorNameToType } from '../../util';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (sensor: SensorModel) => void;
    onError?: (error: Error) => void;
    organisationId?: string;
  };

const UnconnectedCreateSensorForm: React.FC<Props> = ({
  createSensorConnect,
  getOrganisationsConnect,
  loading,
  error,
  sensorCreated,
  organisations,
  organisationId: oId,
  onCreated,
  onError,
}) => {
  React.useEffect(() => {
    getOrganisationsConnect({
      limit: INT_MAX,
      offset: 0,
    });
  }, []);

  React.useEffect(() => {
    if (sensorCreated) {
      notification.success({
        message: 'Sensor created',
      });
      if (onCreated != null) {
        onCreated(sensorCreated);
      }
    }
  }, [sensorCreated]);

  React.useEffect(() => {
    if (error) {
      notification.error({
        message: 'Sensor creation error',
        description: error?.message,
      });
      if (onError != null) {
        onError(error);
      }
    }
  }, [error]);

  const [form] = Form.useForm();
  const [organisationId, setOrganisationId] = React.useState(oId || '');

  const onSubmit = (values: Store) => {
    const { type, organisationId } = values;
    createSensorConnect({ type, organisationId: oId || organisationId });
  };

  const onOrganisationIdChanged = (organisationId: string) => {
    setOrganisationId(organisationId);
    form.setFieldsValue({ organisationId });
  };

  const availableSensorTypes = [
    SensorType.YDS,
    SensorType.IDS,
    SensorType.SAP,
    SensorType.SES,
    SensorType.YSS,
    SensorType.NDP,
    SensorType.STOF,
    SensorType.LUX
  ];

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item label="Sensor Type" name="type" rules={[{ required: true }]}>
        <Select placeholder="Select sensor type" allowClear>
          {availableSensorTypes.map((sensorType: SensorType) => (
            <Option key={sensorType} value={sensorType}>
              {getSensorNameToType(sensorType)}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {!oId && (
        <Form.Item
          label="Organisation"
          name="organisationId"
          rules={[{ required: false }]}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <Select
                placeholder="Select organisation"
                allowClear
                onChange={onOrganisationIdChanged}
                value={organisationId ? organisationId : undefined}
              >
                {organisations.map((organisation) => (
                  <Option key={organisation.id} value={organisation.id}>
                    {organisation.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Sensor
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.sensing.create.loading && state.organisations.list.loading,
  error: state.sensing.create.error || state.organisations.list.error,
  sensorCreated: state.sensing.create.sensor,
  sensors: state.sensing.list.sensors,
  organisations: state.organisations.list.organisations,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createSensorConnect: createSensor,
      getOrganisationsConnect: getOrganisations,
    },
    dispatch,
  );
};

export const CreateSensorForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateSensorForm);
